<template>
    <card type="chart">
        <template slot="header">
            <div class="row">
                <div class="col-sm-6" :class="isRTL ? 'text-right' : 'text-left'">
                    <h5 class="card-category">Tillered Nodes</h5>
                    <h2 class="card-title">Total Traffic (TB)</h2>
                </div>
                <div class="col-sm-6 d-flex d-sm-block">
                    <div class="btn-group btn-group-toggle" :class="isRTL ? 'float-left' : 'float-right'"
                        data-toggle="buttons">
                        <label v-for="(option, index) in bigLineChartCategories" :key="option.name"
                            class="btn btn-sm btn-primary btn-simple"
                            :class="{ active: bigLineChart.activeIndex === index }" :id="index">
                            <input type="radio" @click="initBigChart(index)" name="options" autocomplete="off"
                                :checked="bigLineChart.activeIndex === index" />
                            <span class="d-none d-sm-block">{{ option.name }}</span>
                            <span class="d-block d-sm-none">
                                <i :class="option.icon"></i>
                            </span>
                        </label>
                    </div>
                </div>
            </div>
        </template>
        <div class="chart-area">
            <line-chart style="height: 100%" ref="bigChart" :chart-data="bigLineChart.chartData"
                :gradient-colors="bigLineChart.gradientColors" :gradient-stops="bigLineChart.gradientStops"
                :extra-options="bigLineChart.extraOptions">
            </line-chart>
        </div>
    </card>
</template>
<script>
import LineChart from '@/components/Charts/LineChart';
import * as chartConfigs from '@/components/Charts/config';
import config from '@/config';


let bigChartLabels = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC']

const bigChartDatasetOptions = {
    fill: true,
    borderColor: config.colors.primary,
    borderWidth: 2,
    borderDash: [],
    borderDashOffset: 0.0,
    pointBackgroundColor: config.colors.primary,
    pointBorderColor: 'rgba(255,255,255,0)',
    pointHoverBackgroundColor: config.colors.primary,
    pointBorderWidth: 20,
    pointHoverRadius: 4,
    pointHoverBorderWidth: 15,
    pointRadius: 4,
}

export default {
    name: 'traffic-chart',
    props: {
        rawChartData: Object,
    },
    components: {
        LineChart,
    },
    data() {
        return {
            bigLineChart: {
                activeIndex: 0,
                chartData: {
                    datasets: [{
                        ...bigChartDatasetOptions,
                        data: []
                    }],
                    labels: bigChartLabels
                },
                extraOptions: chartConfigs.purpleChartOptions,
                gradientColors: config.colors.primaryGradient,
                gradientStops: [1, 0.4, 0],
                categories: []
            },
        }
    },
    methods: {
        initBigChart(index) {
            let chartData = {
                datasets: [{
                    ...bigChartDatasetOptions,
                    data: this.chartData[index]
                }],
                labels: bigChartLabels
            };
            this.$refs.bigChart.updateGradients(chartData);
            this.bigLineChart.chartData = chartData;
            this.bigLineChart.activeIndex = index;
        }
    },
    mounted() {
        this.initBigChart(0);
    },
    computed: {
        isRTL() {
            return this.$rtl.isRTL;
        },
        chartData() {
            let raw = this.rawChartData;
            if (!raw) return [];
            if (!raw.traffic) return [];

            console.log(this.rawChartData);

            return [
                this.rawChartData.traffic.total,
                this.rawChartData.traffic.egress,
                this.rawChartData.traffic.ingress,
            ];
        },
        bigLineChartCategories() {
            return [
                { name: 'All Nodes', icon: 'tim-icons icon-single-02' },
                { name: 'Exit Nodes', icon: 'tim-icons icon-gift-2' },
                { name: 'Entry Nodes', icon: 'tim-icons icon-tap-02' },
            ];
        },
        storeUsers() {
            return this.$store.state.users;
        },
        storeUserProfile() {
            return this.storeUsers.userProfile;
        },
        isLoading() {
            if (this.storeUserProfile.data === undefined) {
                return true;
            }
            return !this.storeUserProfile.data.active
        },
    },
    watch: {
        rawChartData: {
            handler(newValue) {
                if (newValue) {
                    this.initBigChart(0);
                }
            },
            deep: true,
        },
    }
}
</script>
