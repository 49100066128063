<template>

    <card>
        <h6>PRE-INVITE MEMBERS</h6>

        <div class="input-group mb-3">
            <input type="text" v-model="members.currentEmail" class="form-control" placeholder="Member's Email"
                aria-label="Member's Email" aria-describedby="basic-addon2" style="height: 100%;" @keyup.enter="() => members.addUser(members.currentEmail)">
            <div class="input-group-append">
                <button class="btn btn-outline-secondary m-0 tim-icons icon-simple-add"
                    @click="() => members.addUser(members.currentEmail)" :disabled="!isValid" type="button" />
            </div>
        </div>

        <div class="overflow-scroll">
            <div v-for="(item, key) in members.userList" :key="key" class="d-flex justify-content-between">
                <p class="text-primary my-auto">{{ item }}</p>
                <p class="tim-icons icon-simple-remove text-danger my-auto" style="cursor: pointer;"
                    @click="() => members.removeUser(item)"></p>
            </div>
        </div>
    </card>

</template>
<script>

import model from 'src/model';

export default {
    data() {
        return {
            members: {
                currentEmail: '',
                userList: [],

                softReset() {
                    this.currentEmail = '';
                },
                /**
                 * Add a new User to the modal list
                 * @param {string} email A user's email to add
                 */
                addUser(email) {
                    if (!model.validateEmail(email)) return; // Invalid Email (Throw an error)
                    this.userList.push(email.trim());
                    this.softReset();
                },
                removeUser(email) {
                    this.userList = this.userList.filter((x) => x.trim() !== email.trim());
                },
                reset() {
                    this.softReset();
                    this.userList = [];
                },
                export() {
                    let payload = {
                        "userInviteEmailList": this.userList,
                    };
                    return payload;
                }
            },
        };
    },
    methods: {
        onDataUpdate(val) {

            // Emit new data to parent
            this.$emit('input', val);
        }
    },
    computed: {
        isValid() {
            return model.validateEmail(this.members.currentEmail) && !this.members.userList.includes(this.members.currentEmail);
        },
    },
    watch: {
        // Check if any data has changed
        'members.userList': {
            handler(val) {
                if (val) {
                    this.onDataUpdate(val);
                }
            },
            deep: true,
        }
    }
}
</script>