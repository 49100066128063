<template>
    <card
      type="secondary"
      class="border-0 mb-0">
      <form @submit.prevent="onEditNetwork">
        <h4 slot="header" class="modal-title" id="modal-title-new">Edit Network</h4>
        <div class="mt-2"></div>
        <div class="row">
          <div class="col-md-12">
            <base-input
              type="text"
              label="Name"
              v-model="mNetwork.name"
            >
            </base-input>
          </div>
        </div>
        <div class="mt-4"></div>
        <div class="d-flex flex-row justify-content-between">
          <base-button native-type="submit" type="primary" class="btn-fill" :disabled="!isValid">
            Save
          </base-button>
          <base-button type="secondary" class="btn-fill" v-on:click.prevent="onCancel">
            Back
          </base-button>
        </div>
      </form>
    </card>
  </template>
  <script>
  
  const LOG_TAG = 'edit_network';
  
  export default {
    props: {
      
      selectedNetwork: {
        type: Object,
        default: function() {
          return {
            uuid: "",
            name: "",
          };
        }
      },
      onSave: {
        type: Function,
        required: true,
      },
      onCancel: {
        type: Function,
        required: true,
      },
      reset: {
        type: Number,
        default: function() {
          return 0;
        }
      }
    },
    data() {
      return {
        mNetwork: {
          uuid: "",
          name: "",
          valid: false,
          validate() {
            this.valid = this.name != ''
          },
          reset() {
            this.name = '';
          },
        },
      };
    },
    computed: {
      isValid() {
        return this.mNetwork.valid;
      },
      networkData() {
        let payload = {
          "uuid": this.mNetwork.uuid,
          "name": this.mNetwork.name,
        };
        return payload;
      }
    },
    watch: {
      reset: function(value) {
        const vm = this;
        window.logger.d(LOG_TAG, 'watch:reset', value);
        vm.mNetwork.reset();
      },
      'mNetwork.name': function(value) {
        const vm = this;
        vm.mNetwork.validate();
      },
      selectedNetwork: function(value) {
        const vm = this;
        vm.mNetwork.uuid = value.uuid;
        vm.mNetwork.name = value.name;
      }
    },
    mounted() {},
    methods: {
      onEditNetwork() {
        const vm = this;
        vm.onSave(vm.networkData);
      }
    },
  
  };
  </script>
  <style></style>