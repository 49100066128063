<template>
    <div class="container-lg">
        <div class="col-md-12">
            <div class="row">
                <div v-for="(alert, idx) in alerts.state.alerts" class="col-md-12 alert-container" :key="idx">
                    <base-alert :type="alert.type" :icon="alert.icon" :storeindex="idx" dismissible>
                        <strong>{{ alert.title }}:</strong> {{ alert.message }}
                    </base-alert>
                </div>
            </div>
    
            <p>Create a new organisation and pre-invite other members into it. Every organisation requires a primary contact
                as a communication point for Tillered. </p>
    
            <div class="col-md-12 row mt-4">
                <div class="col-md-6 d-flex flex-column">
                    <OrgDetails @input='OnDetailsUpdate' />
                    <PreInviteMembers class="flex-grow-1" @input='OnMemberUpdate' />
                </div>
                <div class="col-md-6">
                    <PrimaryContact @input='OnContactUpdate' />
                </div>
            </div>
    
            <div class="d-flex flex-row-reverse mr-5">
                <button class="btn btn-secondary ml-4" @click='OnCancel'>Cancel</button>
                <button class="btn btn-primary" @click='OnCreate'>Create</button>
            </div>
        </div>
    </div>
</template>
<script>

import alerts from "src/store/modules/alerts";
import model from "src/model";

import OrgDetails from 'src/tillered/components/contact/OrganisationDetails.vue';
import PrimaryContact from 'src/tillered/components/contact/PrimaryContact.vue';
import PreInviteMembers from 'src/tillered/components/contact/PreInviteMembers.vue';

const LOG_TAG = 'create_organisation';

// Get an instance of `PhoneNumberUtil`.
const phoneUtil = require('google-libphonenumber').PhoneNumberUtil.getInstance();

export default {
    name: "create_organisation",
    components: {
        OrgDetails,
        PrimaryContact,
        PreInviteMembers,
    },
    data() {
        return {
            alerts,
            details: {},
            members: [],
            contact: {},
        }
    },
    methods: {
        OnDetailsUpdate(details) {
            this.details = details;
        },
        OnMemberUpdate(members) {
            this.members = members;
        },
        OnContactUpdate(contact) {
            this.contact = contact;
        },
        OnCancel(e) {
            window.router.push({ name: 'All Organisations' });
        },
        OnCreate(e) {
            if (!this.isValid) {
                window.store.dispatch('addError', {
                    title: 'Warning',
                    message: 'Not all required fields are filled.',
                    timeout: 200
                })

                return;
            }

            if (this.details && this.contact) {
                window.bloc.update.createContact(this.contact,
                    (contact) => {
                        console.log(contact.data.uuid);

                        const orgData = {
                            name: this.details.name,
                            contactUuid: contact.data.uuid,
                            notes: this.details.notes
                        }

                        window.bloc.update.createOrganisation(
                            orgData,
                            (org) => {

                                // Invite Members
                                if (this.members) {
                                    this.inviteMembers(org.data.uuid);
                                }

                                // Switch Active Org
                                this.switchActiveOrg(org.data.uuid);

                                // Alert Success
                                window.store.dispatch('addSuccess', {
                                    title: 'Success',
                                    message: 'Organisation created successfully',
                                    timeout: 300
                                })

                                // Route to new Active Org
                                window.router.push({ name: 'Manage Active Org' });
                            }, (error) => {
                                window.store.dispatch('addError', {
                                    title: 'Error',
                                    message: model.error(error.error).formattedErrorMessage(),
                                    timeout: 300
                                })
                            }
                        );
                    }, (error) => {
                        window.store.dispatch('addError', {
                            title: 'Error',
                            message: model.error(error.error).formattedErrorMessage(),
                            timeout: 300
                        })
                    })
            }
        },
        inviteMembers(orgUuid) {
            const preinvite = {
                "userInviteEmailList": this.members
            };

            window.webapi.organisation.inviteMembers(
                orgUuid,
                preinvite,
                () => { },
                (error) => {
                    window.store.dispatch('addError', {
                        title: 'Error',
                        message: model.error(error.error).formattedErrorMessage(),
                        timeout: 3000
                    })
                }
            );
        },
        switchActiveOrg(orgUuid) {
            window.webapi.profile.switchUserProfileOrg(
                orgUuid,
                () => {
                    window.bloc.account.syncUserOrganisations();
                    window.bloc.account.syncUserProfile();
                    window.bloc.account.syncUserNodes();
                    window.bloc.account.syncUserNetworks();
                },
                (error) => {
                    console.log(error);
                }
            )
        }
    },
    mounted() {
        window.store.dispatch('removeAllAlerts');
    },
    computed: {
        isValid() {
            // Org Details Validation
            if (!this.details) return false;
            if (this.details.name == '') return false;
            
            // Contact Validation
            if (!this.contact) return false;
            if (this.contact.fname == '' 
                || this.contact.lname == ''
                || !model.validateEmail(this.contact.email)) return false;
            
            try {
                var tel = phoneUtil.parse(this.contact.phone);
                if (!phoneUtil.isValidNumber(tel)) return false;
            } catch (error) {
                return false;
            }

            // Check Contact Address Validation
            if (!this.contact.addr) return false;
            if (this.contact.addr.physical.addr1 == ''
                || this.contact.addr.physical.city == ''
                || this.contact.addr.physical.state == ''
                || this.contact.addr.physical.postal == ''
                || this.contact.addr.physical.country == ''
                || this.contact.addr.postal.addr1 == ''
                || this.contact.addr.postal.city == ''
                || this.contact.addr.postal.state == ''
                || this.contact.addr.postal.postal == ''
                || this.contact.addr.postal.country == '') return false;

            return true;
        }
    }
}
</script>