<template>
    <card
      type="secondary"
      class="border-0 mb-0">
      <form @submit.prevent="onDeactivateNode">
        <h4 slot="header" class="modal-title" id="modal-title-new">Deactivate {{target}}</h4>
        <div class="mt-2"></div>
        <div class="row">
          <div class="col-md-12">

            <span>I understand that this operation cannot be undone.</span><br>
            <base-switch v-model="valid" on-text="Yes" off-text="No"></base-switch>

          </div>
        </div>
        <div class="mt-4"></div>
        <div class="d-flex flex-row justify-content-between">
          <base-button native-type="submit" type="primary" class="btn-fill" :disabled="!valid">
            Deactivate
          </base-button>
          <base-button type="secondary" class="btn-fill" v-on:click.prevent="onCancel">
            Back
          </base-button>
        </div>
      </form>
    </card>
  </template>
  <script>
  
  const LOG_TAG = 'deactivate_node';
  
  import BaseSwitch from 'src/components/BaseSwitch.vue';


  export default {
    components: {
        BaseSwitch,
    },
    props: {
      onSave: {
        type: Function,
        required: true,
      },
      onCancel: {
        type: Function,
        required: true,
      },
      target: {
        type: String,
        required: false,
        default: 'Node',
      }
    },
    data() {
        return {
          valid: false,
      };
    },
    computed: {
      storeState() {
        return this.$store.state;
      },
      storeOrganisations() {
        return this.$store.state.organisations;
      },
      isValid() {
        return this.valid !== 0 && this.$store.getters.userProfile.hasCompany();
      },
    },
    mounted() {},
    methods: {
        onDeactivateNode() {
        const vm = this;
        vm.onSave(true);
      },
      onAgree() {
        console.log(this.valid)

        if (this.valid) {
            this.valid = false;
        } else {
            this.valid = true;
        }
      }
    },
  
  };
  </script>
  <style></style>
  