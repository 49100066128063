<template>
  <card
    type="secondary"
    class="border-0 mb-0">
    <form @submit.prevent="onCreateNode">

      <modal :show.sync="showNode" body-classes="p-0" modal-classes="modal-dialog-centered modal-sm">
        <deactivate-node :on-save="onDeactivateNode" :on-cancel="onCloseModal">
        </deactivate-node>
      </modal>

      <div class="row">
        <div class="col-md-6">
          <base-input
            type="text"
            label="Name"
            v-model="mNode.selected.name"
          >
          </base-input>
        </div>
        <div class="col-md-6">
          <base-input
            type="text"
            :disabled="true"
            label="Type"
            v-model="mNode.selected.type">
          </base-input>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <base-input
            type="text"
            :disabled="true"
            label="Serial Number"
            v-model="mNode.selected.serialnumber"
          >
          </base-input>
        </div>
      </div>
      <!-- public ipv4, 6-->
      <div class="row">
        <div class="col-md-6">
          <base-input
            type="text"
            :disabled="true"
            label="Public IP"
            v-model="mNode.selected.public_ipv4"
          >
          </base-input>
        </div>
        <div class="col-md-6">
          <base-input
            type="text"
            :disabled="true"
            label="Public IP V6"
            v-model="mNode.selected.public_ipv6">
          </base-input>
        </div>
      </div>
      <!-- cloud instance-->
      <div class="row">
        <div class="col-md-6">
          <base-input
            type="text"
            :disabled="true"
            label="Cloud Instance ID"
            v-model="mNode.selected.cloud_instance_id"
          >
          </base-input>
        </div>
        <div class="col-md-6">
          <base-input
            type="text"
            :disabled="true"
            label="Cloud Provider"
            v-model="mNode.selected.cloud_provider">
          </base-input>
        </div>
      </div>
      <!-- fqdn / template -->
      <div class="row">
        <div class="col-md-6">
          <base-input
            type="text"
            :disabled="true"
            label="FQDN"
            v-model="mNode.selected.fqdn"
          >
          </base-input>
        </div>
        <div class="col-md-6">
          <base-input
            type="text"
            :disabled="true"
            label="Template"
            v-model="mNode.selected.template">
          </base-input>
        </div>
      </div>
      <div class="mt-4"></div>
      <div class="d-flex flex-row justify-content-between">
        <div>
          <base-button native-type="submit" type="warning" class="btn-fill m-1" v-on:click.prevent="showNode=true">
            Deactivate Node
          </base-button>
        </div>
        <div class="d-flex flex-row">
          <base-button native-type="submit" type="primary" class="btn-fill m-1">
            Save
          </base-button>
          <base-button type="secondary" class="btn-fill m-1" v-on:click.prevent="onCancel">
            Back
          </base-button>
        </div>
      </div>

    </form>
  </card>
</template>
<script>

const LOG_TAG = 'edit_node';
import model from 'src/model';

import { Modal } from 'src/components'

import DeactivateNode from 'src/tillered/forms/DeactivateNode';

export default {
  components: {
    DeactivateNode,
    Modal,
  },
  props: {
    selectedNode: {
      type: Object,
      default: function() {
        return model.node();
      }
    },
    onSave: {
      type: Function,
      required: true,
    },
    onCancel: {
      type: Function,
      required: true,
    },
    onDeactivate: {
      type: Function,
      required: true,
    },
    reset: {
      type: Number,
      default: function() {
        return 0;
      }
    }
  },
  data() {
    return {
      mNode: {
        selected: model.node(),
        reset() {
          this.selected = model.node();
        },
      },
      showNode: false,
    };
  },
  watch: {
    selectedNode: function(value) {
      const vm = this;
      window.logger.d(LOG_TAG, 'watch:selectedNode', value);
      if (value) {
        vm.mNode.selected = value;
      } else {
        vm.mNode.selected = model.node();
      }
    },
    reset: function(value) {
      const vm = this;
      window.logger.d(LOG_TAG, 'watch:reset', value);
      vm.mNode.reset();
    },
  },
  methods: {
    onCreateNode() {
      const vm = this;
      vm.onSave(vm.mNode.selected);
    },
    onDeactivateNode() {
      const vm = this;
      vm.showNode = false;
      vm.onDeactivate();
    },
    onCloseModal() {
      const vm = this;
      vm.showNode = false;
    },
  },
  computed: {
    formattedHeader() {
      const vm = this;
      if (vm.mNode.selected) {
        return vm.mNode.selected.getName();
      } else {
        return 'New Node';
      }
    },
  },
  mounted() {
    const vm = this;
    vm.mNode.selected = vm.selectedNode;
  }
};
</script>
<style></style>
