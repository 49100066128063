<template>
    <card
      type="secondary"
      class="border-0 mb-0">
      <form @submit.prevent="onJoinOrganisation">
  
        <h6 slot="header" class="modal-title" id="modal-title-new">Join Organisation</h6>
        <div class="mt-2"></div>
        <div class="row">
            <div class="col-md-12">
                <base-input
                    type="text"
                    label="Organisation UUID"
                    v-model="mOrganisation.uuid"
                />
            </div>
        </div>
        <div class="mt-4"></div>
        <div class="d-flex flex-row justify-content-between">
          <base-button native-type="submit" type="primary" class="btn-fill" :disabled="!isValid">
            Save
          </base-button>
          <base-button type="secondary" class="btn-fill" v-on:click.prevent="onCancel">
            Cancel
          </base-button>
        </div>
  
      </form>
    </card>
  </template>
  <script>
  
  import model from "../../model";

  const LOG_TAG = 'join_organisation';
  
  export default {
    props: {
      onSave: {
        type: Function,
        required: true,
      },
      onCancel: {
        type: Function,
        required: true,
      },
      reset: {
        type: Number,
        default: function() {
          return 0;
        }
      }
    },
    data() {
      return {
        mOrganisation: {
          uuid: '',
          reset() {
            this.uuid = '';
          },
          export() {
            let payload = {
              "uuid": this.uuid,
            };
            return payload;
          }
        }
      };
    },
    computed: {
      storeState() {
        return this.$store.state;
      },
      storeUsers() {
        return this.$store.state.users;
      },
      storeUserContacts() {
        return this.storeUsers.contacts;
      },
      isValid() {
        return model.validUUID(this.mOrganisation.uuid);
      },
    },
    watch: {
      reset: function(value) {
        const vm = this;
        window.logger.d(LOG_TAG, 'watch:reset', value);
        vm.mOrganisation.reset();
      },
    },
    methods: {
      onJoinOrganisation() {
        const vm = this;
        vm.onSave(vm.mOrganisation.export());
      }
    },
  };
  </script>
  <style></style>
  