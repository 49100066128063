<template>
    <card>
        <h6>ORGANISATION DETAILS</h6>
        <base-input type="text" label="Organisation Name" v-model="details.name" required noAutocomplete />
        <base-input label="Notes">
            <textarea class="form-control" id="notes" rows="3" v-model="details.notes" />
        </base-input>
    </card>
</template>
<script>
export default {
    data() {
        return {
            details: {
                name: '',
                notes: '',
            },
        };
    },
    methods: {
        onDetailsUpdate(val) {
            // Emit new data to parent
            this.$emit('input', val);
        }
    },
    watch: {
        // Check if any data has changed
        details: {
            handler(val) {
                if (val) {
                    this.onDetailsUpdate(val);
                }
            },
            deep: true,
        }
    }
}
</script>