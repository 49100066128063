<template>

    <!-- Node Status -->
    <el-tooltip effect="dark" placement="right" :disabled="getStatusColour === 'var(--success)'">
        <pre slot="content" class="m-0 p-0"
            style="font-family: 'Poppins', sans-serif; font-size: 0.875rem; font-weight: 400; line-height: 1.5; color: #FFFFFF;">{{ getToolmessage }}</pre>
        <div class="m-2" :style="{ backgroundColor: getStatusColour, width: '10px', cursor: 'pointer' }"></div>
    </el-tooltip>

</template>
<script>

export default {
    name: 'status-indicator',
    props: {
        stylecolor: {
            type: String,
            default: 'var(--success)',
            description: 'Colour for the status'
        },
        toolmessage: {
            type: String,
        }
    },
    computed: {
        getStatusColour() {
            return this.stylecolor;
        },
        getToolmessage() {
            return this.toolmessage;
        }
    }
}

</script>