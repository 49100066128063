<template>
  <card card-body-classes="table-full-width">
    <h4 slot="header" class="card-title">Interfaces</h4>
    <div>
      <div class="col-12 d-flex flex-column flex-md-row justify-content-md-between justify-content-center mb-4">
        <!-- Page Size -->
        <el-select class="col-md-2 col-12 p-0 my-2 select-primary pagination-select mx-md-0 mx-auto"
          v-model="pagination.perPage" placeholder="Per page">
          <el-option class="select-primary" v-for="item in pagination.perPageOptions" :key="item" :label="item"
            :value="item">
          </el-option>
        </el-select>

        <!-- Search Bar -->
        <div class="col-md-9 col-12 p-0 my-2">
          <input type="text" :style="searchStyle" v-model="searchQuery" class="form-control" placeholder="Search">
        </div>

      </div>

      <div>
        <!-- Header -->
        <div class="col-12 d-none d-md-flex justify-content-between" style="border-bottom: solid 2px #525f7f;">
          <div class="col-2">
            <h6>Name</h6>
          </div>
          <div class="col-3 text-center">
            <h6>IP</h6>
          </div>
          <div class="col-2 text-center">
            <h6>MAC</h6>
          </div>
          <div class="col-5 text-right">
            <h6>Options</h6>
          </div>

        </div>

        <!-- Body -->
        <div v-for="(row, index) in queriedData" :key="row.uuid"
          class="col-12 d-md-flex justify-content-between my-2 pb-2" style="border-bottom: solid 1px #525f7f;">

          <!-- Name -->
          <div class="col-md-2 col-12 d-flex justify-content-between">
            <div class="d-md-none d-block mb-3">
              <h3 class="d-md-none d-block mb-0">{{ row.name }}</h3>
              <h6 style="color: var(--primary); cursor: pointer;" class="d-md-none d-block"
                @click="() => onInterfaceNodeClick(row)">{{ row.targetNodeName === 'N/A' ? '' : row.targetNodeName }}
              </h6>
            </div>

            <div class="d-md-block d-none my-auto">
              <h6>{{ row.name }}</h6>
              <p style="color: var(--primary); cursor: pointer;" @click="() => onInterfaceNodeClick(row)">{{
                  row.targetNodeName === 'N/A' ? '' : row.targetNodeName
              }}</p>
            </div>
          </div>

          <!-- IP -->
          <div class="col-md-3 col-12 d-flex justify-content-between justify-content-md-center">
            <h6 class="d-md-none d-block mr-2">IPs</h6>
            <div class="my-auto text-right text-md-center">
              <span>{{ row.ip4Full }}</span>
              <br>
              <span>{{ row.ip6Full }}</span>
            </div>
          </div>

          <!-- MAC -->
          <div class="col-md-2 col-12 d-flex mb-md-0 mb-2 justify-content-between justify-content-md-center">
            <h6 class="d-md-none d-block my-auto mr-2">MAC</h6>
            <span class="my-auto">{{ row.mac }}</span>
          </div>
          
          <!-- Switch Actions -->
          <div class="col-md-5 col-12 d-flex">
            <div class="my-auto w-100">
              <div class="d-flex justify-content-between justify-content-md-end mb-2">
                <h6 class="col-md-4 text-md-right pl-0 my-auto">Transport</h6>

                <!-- Display a drop down field -->
                <div>
                  <el-select class="select-primary" v-model="row.transport" placeholder="Select" @change="(val) => SetInterfacePrivate(val, index)">
                    <el-option label="Public" value="0"></el-option>
                    <el-option label="Private" value="1"></el-option>
                    <el-option label="Internal VPN" value="2"></el-option>
                  </el-select>
                </div>

              </div>
              <div class="d-flex justify-content-between justify-content-md-end mb-2">
                <h6 class="col-md-4 text-md-right pl-0 my-auto">Mode</h6>

                <div>
                  <el-select class="select-primary" v-model="row.protocol" placeholder="Select" @change="(val) => SetInterfaceKCP(val, index)">
                    <el-option label="TCP" value="0"></el-option>
                    <el-option label="UDP" value="1"></el-option>
                  </el-select>
                </div>

              </div>
              <div class="d-flex justify-content-between justify-content-md-end">
                <h6 class="col-md-6 text-md-right pl-0">Transparent</h6>
                <node-switch v-model="row.isTransparent" on-text="ON" off-text="OFF" true-value="1" false-value="0"
                  @input="(val) => SetInterfaceTransparent(val, index)"></node-switch>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
    <div slot="footer" class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
      <div class="">
        <p class="card-category">
          Showing {{ from + 1 }} to {{ to }} of {{ total }} entries
        </p>
      </div>
      <base-pagination class="pagination-no-border" v-model="pagination.currentPage" :per-page="pagination.perPage"
        :total="total">
      </base-pagination>
    </div>
  </card>
</template>
<script>

const LOG_TAG = 'node_network';

import { Table, TableColumn, Select, Option } from 'element-ui';
import { BasePagination } from 'src/components';
import NodeSwitch from 'src/tillered/components/NodeSwitch.vue';
import model from 'src/model';
import Fuse from 'fuse.js';

export default {
  components: {
    BasePagination,
    // BaseSwitch,
    NodeSwitch,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    //Modal,
  },
  props: {
    // selectedNodeInterface: {
    //   type: Object,
    //   default: function() {
    //     return {};
    //   }
    // },
    method: {
      isReady() {
        return this.$attrs
      },
      transparentEnabled(index, row) {
        const vm = this
        return true
        //return vm.mTableData[index].isTransparent === 1;
      },
    },
    reset: {
      type: Number,
      default: function () {
        return 0;
      }
    }
  },
  data() {
    return {
      pagination: {
        perPage: 50,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50, 100],
        total: 0
      },
      searchQuery: '',
      propsToSearch: ['name', 'ip4Full', 'ip6Full', 'mac'],
      mTableData: [],
      searchedData: [],
      fuseSearch: null,
      interfaceTransport: {
        0: 'Public',
        1: 'Private',
        2: 'Internal VPN'
      },
      interfaceProtocol: {
        0: 'TCP',
        1: 'UDP',
      },
    };
  },
  watch: {
    // selectedNodeInterface: function(value) {
    //   const vm = this;
    //   vm.mNode.selected = value;
    // }
    searchQuery(value) {
      let result = this.mTableData;
      let rv = [];
      if (value !== '') {
        result = this.fuseSearch.search(this.searchQuery);
        for (let i = 0; i < result.length; i++) {
          rv.push(result[i].item)
        }
      }
      this.searchedData = rv;
    },
  },
  computed: {
    /***
     * Returns a page from the searched data or the whole data. Search is performed in the watch section below
     */
    queriedData() {
      let result = this.mTableData.map((row) => {
        row.transport = this.interfaceTransport[row.buildToPrivate];
        row.protocol = this.interfaceProtocol[row.udpBoost];
        return row;
      });
      if (this.searchedData.length > 0) {
        result = this.searchedData;
      }

      return model
        .interfaceFilter(result) /* Filter The Interfaces by Name */
        .slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.searchedData.length > 0
        ? this.searchedData.length
        : this.mTableData.length;
    },
    searchStyle() {
      if (this.searchQuery !== '') {
        let result = this.fuseSearch.search(this.searchQuery);
        if (result.length == 0) {
          return {
            borderColor: 'var(--red)',
            color: 'var(--red)'
          }
        }
      }
      return {}
    },
  },
  methods: {
    DataIndex(index) {
      return this.from + index;
    },
    /*
     * The following 3 functions are callbacks to update the:
     *    - KCP or TCP
     *    - Private or Public
     *    - Transparent or Regular
     * 
     * They update the node interface table object and also send a request to
     * the server to update each node interface depending on which one is 
     * changed.
     */
    SetInterfacePrivate(val, ix) {
      const index = this.DataIndex(ix);
      this.mTableData[index].buildToPrivate = val;
      this.UpdateInterface(this.mTableData[index]);
    },
    SetInterfaceKCP(val, ix) {
      const index = this.DataIndex(ix);
      this.mTableData[index].udpBoost = val;
      this.UpdateInterface(this.mTableData[index]);
    },
    SetInterfaceTransparent(val, ix) {
      const index = this.DataIndex(ix);
      this.mTableData[index].isTransparent = val;
      this.UpdateInterface(this.mTableData[index]);
    },

    // Make Update Call to Set KCP, Transparency, Private
    UpdateInterface(data) {
      // Convert to Node Interface model (This step can be skipped, here for reference)
      let modelData = model.nodeNetworkInterface(data);

      // Format the Data we actually want
      let reqData = {
        uuid: modelData.uuid,
        ip4Full: modelData.ip4Full,
        ip6Full: modelData.ip6Full,
        sockBuffer: modelData.sockBuffer,
        rWindowSize: modelData.rWindowSize,
        sWindowSize: modelData.sWindowSize,
        isTransparent: parseInt(modelData.isTransparent),
        udpBoost: parseInt(modelData.udpBoost),
        buildToPrivate: parseInt(modelData.buildToPrivate),
      };

      // Send Update Request
      window.webapi.nodeinterface.updateInterface(
        reqData.uuid,
        reqData,
        (resp) => { },
        (error) => console.log(error)
      )
    },

    onInterfaceNodeClick(row) {
      window.router.push({ name: 'Node Details', params: { uuid: row.targetNode } });
    },
  },
  mounted() {
    const vm = this;
    const nodeUuid = this.$route.params.uuid;
    window.logger.d(LOG_TAG, 'mounted', nodeUuid);
    window.webapi.nodeinterface.getInterfaces(
      nodeUuid,
      (data) => {
        vm.mTableData = data.data
        this.fuseSearch = new Fuse(data.data, {
          keys: this.propsToSearch,
          threshold: 0.2
        });
      }, (error) => {
        console.log(error)
        //todo stick it in the alert bar.

        //if (null!=onError) onError(error)
      }
    )
  },

};
</script>
<style>
.el-table .cell {
  word-break: normal;
}
</style>
