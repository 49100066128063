<template>
  <div class="container-lg">
    <div v-if="isLoading" class="row">
      <div class="col-12 row justify-content-center mt-5">
        <DotLoader />
      </div>
    </div>
    <div v-else class="col-12">
      <div class="row">
        <div v-for="(alert, idx) in alerts.state.alerts" class="col-md-12 alert-container" :key="idx">
          <base-alert :type="alert.type" :icon="alert.icon" :storeindex="idx" dismissible>
            <strong>{{alert.title}}:</strong> {{alert.message}}
          </base-alert>
        </div>
      </div>

      <div v-if="getDeactivated" class="col-12 d-flex bg-danger py-2 my-2 rounded">
        <h6 class="m-0"> <i class="tim-icons icon-alert-circle-exc"></i> This Node has been Deactivated</h6>
      </div><div v-else></div>
      <div v-if="seenExpiry" class="col-12 d-flex bg-warning py-2 my-2 rounded">
        <h6 class="m-0"> <i class="tim-icons icon-alert-circle-exc"></i> Node data out of date </h6>
      </div><div v-else></div>

      <h2 class="m-0">{{mNode.selected.name}}</h2>
      <span>Last seen: {{ relativeLastSeen }}</span>

      <div class="row mt-2">
        <div class="col-12">
          <edit-node
            :selected-node="mNode.selected"
            :on-save="onSave"
            :on-deactivate="onDeactivate"
            :on-cancel="onCancel">
          </edit-node>
        </div>
      </div>
      <br />
      
      <div class="row">
        <div class="col-6 col-md-3">
          <card>
            <p class="card-category">Provisioning Status</p>
            <h4 class="m-0 text-center">
              <i v-if="mNode.selected.provisioning_state == 'READY'" class="tim-icons icon-check-2 text-success"></i>
              <i v-else-if="mNode.selected.provisioning_state == 'PROVISIONING'" class="tim-icons icon-alert-circle-exc text-warning"></i>
              <i v-else-if="mNode.selected.provisioning_state == ''" class="tim-icons icon-simple-remove text-danger"></i>
              
              &nbsp;{{ mNode.selected.provisioning_state}}
            </h4>
          </card>
        </div>
        <div class="col-6 col-md-3">
          <card>
            <p class="card-category">Authorisation</p>
            <h4 class="m-0 text-center">
              <i v-if="mNode.selected.authorized == 1" class="tim-icons icon-check-2 text-success"></i>
              <i v-else-if="mNode.selected.authorized == 0" class="tim-icons icon-alert-circle-exc text-warning"></i>
              
              &nbsp;{{ authorisedState }}
            </h4>
          </card>
        </div>
        <div class="col-6 col-md-3">
          <card>
            <p class="card-category">IP Public</p>
            <h4 class="m-0 text-center">{{mNode.selected.public_ipv4 }}</h4>
          </card>
        </div>
        <div class="col-6 col-md-3">
          <card>
            <p class="card-category">IP Private</p>
            <h4 class="m-0 text-center">{{mNode.selected.private_ipv4 }}</h4>
          </card>
        </div>
      </div>
  
      <div class="row">
        <div class="col-md-12">
          <node-network
            :selected-node="mNode.selected">
          </node-network>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import BaseAlert from "src/components/BaseAlert";
import alerts from "src/store/modules/alerts";
import model from "src/model";
import EditNode from "src/tillered/forms/EditNode"
import NodeNetwork from "src/tillered/forms/NodeNetwork"
import DotLoader from 'src/tillered/components/DotLoader.vue';


const LOG_TAG = 'node_details';

export default {
  name: "NodeDetails",
  components: {
    BaseAlert,
    EditNode,
    NodeNetwork,
    DotLoader
  },
  props: {
    selectedNode: {
      type: Object,
      default: function() {
        return model.node();
      }
    },
  },
  data() {
    return {
      alerts,
      mNode: {
        selected: model.node()
      },
      nNodeInterfaces: []
    };
  },
  computed: {
    storeState() {
      return this.$store.state;
    },
    storeNodes() {
      return this.$store.state.nodes;
    },
    storeNodesList() {
      return this.storeNodes.list;
    },
    getDeactivated() {
      return !this.mNode.selected.active;
    },
    seenExpiry() {
      return model.nodeOutOfDate(this.mNode.selected);
    },
    authorisedState() {
      const vm = this;
      if (vm.mNode.selected.authorized === 1) {
        return 'Authorised';
      } else {
        return 'Not Authorised';
      }
    },
    isLoading() {
      return this.mNode.selected.uuid === '';
    },
    relativeLastSeen() {
      const nodeLastSeen = Date.parse(this.mNode.selected.lastSeen);
      let delta = Date.now() - nodeLastSeen;

      if (delta < 1000) return "now";
      else if (delta < 1000 * 60) return `${Math.floor(delta / 1000)} seconds ago`;
      else if (delta < 1000 * 60 * 60) return `${Math.floor(delta / (1000 * 60))} minutes ago`;
      else if (delta < 1000 * 60 * 60 * 24) return `${Math.floor(delta / (1000 * 60 * 60))} hours ago`;
      else return `${Math.floor(delta / (1000 * 60 * 60 * 24))} days ago`;
    }
  },
  mounted() {
    window.store.dispatch('removeAllAlerts');
    
    const vm = this;
    const nodeUuid = this.$route.params.uuid;
    const selected = vm.storeNodesList.find((node) => node.getUuid() === nodeUuid);
    if (selected) {
      vm.mNode.selected = model.node(selected);
    } else {
      window.webapi.node.getNode(
        nodeUuid,
        (data) => {
          vm.mNode.selected = model.node(data.data);
        }, (error) => {
          console.log(error)
        }
      )
    }
    window.logger.d(LOG_TAG, 'mounted', nodeUuid, selected);
  },
  methods: {
    onSave(data) {
      const vm = this;
      window.bloc.update.updateNode(
        vm.mNode.selected.getUuid(),
        data,
        (data) => {
          window.store.dispatch('addSuccess', {
            title: 'Success',
            message: 'Node Updated Succesfully',
            timeout: 3000 }
          )
        }, (error) => {
          window.store.dispatch('addError', {
            title: 'Error',
            message: error.error.message + ". (" + error.error.errors[0].reason+")",
            timeout: 3000 }
          )
        }
      )
    },
    onCancel() {
      window.router.back();
    },
    onDeactivate() {
      const vm = this;
      window.webapi.node.deactivateNode(
        vm.mNode.selected.getUuid(),
        (data) => {
          window.store.dispatch('addSuccess', {
            title: 'Success',
            message: 'Node Deactivated Succesfully',
            timeout: 3000 }
          )
          window.router.back();
        }, (error) => {
          console.log(error)
          window.store.dispatch('addError', {
            title: 'Error',
            message: "Failed to Deactivate Node: " + error.error.errors[0].reason,
            timeout: 3000 }
          )
        }
      )
    }
  },
}
</script>
