<template>
  <div class="container-lg">
    <div v-if="isLoading" class="row">
      <div class="col-12 row justify-content-center mt-5">
        <DotLoader />
      </div>
    </div>
    <div v-else>
      <div class="row">
        <div v-for="(alert, idx) in alerts.state.alerts" class="col-md-12 alert-container" :key="idx">
          <base-alert :type="alert.type" :icon="alert.icon" :storeindex="idx" dismissible>
            <strong>{{ alert.title }}:</strong> {{ alert.message }}
          </base-alert>
        </div>
      </div>

      <div>
        <h2>Organisations</h2>
        <p>All of your Nodes/Routers are grouped together by organisations. This could just be a business, club, yacht,
          charity or even an individual. You can manage which users are members of your organisation and grant them
          access to mange the node. Or you can just join an existing organisation using their organisation key.</p>
        <br />
      </div>

      <div v-if="activeOrg">
        <div class="">
          <card>
            <div class="d-flex justify-content-between">
              <i class="col-1 my-auto tim-icons icon-molecule-40" style="font-size: 1.8rem;"></i>
              <div class="col-8">
                <h3 class="m-0">{{ storeUserProfile.getCompanyName() }}</h3>
                <span>ACTIVE ORGANISATION</span>
              </div>
              <base-button block type="success" @click="manageorg">
                Manage
              </base-button>
            </div>
          </card>
        </div>
        
        <modal :show.sync="newOrgModal.show" body-classes="p-3" modal-classes="modal-dialog-centered modal-sm" modalContentClasses="card">
            <div class="row">
              <!-- <p>You can either Create New Organisation, or Join an Existing Organisation. Please Select.</p> -->
              <section class="col-md-6">
                <base-button block type="success" @click="onCreate">
                  Create Organisation
                </base-button>
              </section>
              <section class="col-md-6">
                <base-button block type="success" @click="() => {joinOrgModal.joinOrganisation = true; newOrgModal.show = false;}">
                  Join Organisation
                </base-button>
              </section>
            </div>
        </modal>
        <modal :show.sync="joinOrgModal.joinOrganisation" body-classes="p-0"
          modal-classes="modal-dialog-centered modal-sm">
          <join-organisation :reset="joinOrgModal.reset" :on-save="onJoinOrganisation" :on-cancel="onCloseJoinModal">
          </join-organisation>
        </modal>
        <div class="row mt-3">
          <div class="col-12">
            <card card-body-classes="table-full-width">
              <h4 slot="header" class="card-title">All Joined Organisations</h4>
              <div>
                <!-- Search Header -->
                <div
                  class="col-12 d-flex flex-column flex-md-row justify-content-md-between justify-content-center mb-4">
                  <!-- Page Size -->
                  <el-select class="col-md-2 col-12 p-0 my-2 select-primary pagination-select mx-md-0 mx-auto"
                    v-model="pagination.perPage" placeholder="Per page">
                    <el-option class="select-primary" v-for="item in pagination.perPageOptions" :key="item"
                      :label="item" :value="item">
                    </el-option>
                  </el-select>

                  <!-- Search Bar -->
                  <div class="col-md-8 col-12 p-0 my-2">
                    <input type="text" :style="searchStyle" v-model="searchQuery" class="form-control" placeholder="Search">
                  </div>

                  <!-- New Org (Join or Create) -->
                  <div class="col-md-1 col-12 p-0 my-2">
                    <base-button class="mt-0" block type="success" @click="newOrgModal.show = true">
                      <i class="tim-icons icon-simple-add"></i>
                    </base-button>
                  </div>
                </div>

                <!-- Table -->
                <div>
                  <!-- Header -->
                  <div class="col-12 d-flex justify-content-between" style="border-bottom: solid 2px #525f7f;">
                    <div class="col-1"></div>
                    <div class="col-7">
                      <h6>Name</h6>
                    </div>
                    <div class="col-4">
                      <h6>Contact</h6>
                    </div>
                  </div>

                  <!-- Body -->
                  <div v-for="row in queriedData" :key="row.uuid"
                    class="col-12 d-flex justify-content-between my-2 pb-2" style="border-bottom: solid 1px #525f7f;">

                    <div class="col-1 d-flex">
                      <i v-if="storeUserProfile.getCompanyName() === row.name"
                        class=" my-auto tim-icons icon-molecule-40" style="font-size: 1.8rem;"></i>
                    </div>

                    <div class="col-7 d-flex">
                      <div class="my-auto">
                        <h6 class="mb-0">{{ row.name }}</h6>
                        <span>{{ row.uuid }}</span>
                      </div>
                    </div>

                    <div class="col-4 d-flex">
                      <div class="my-auto">
                        <h6 class="mb-0" v-if="row.contact">{{ row.contact.firstname }} {{ row.contact.lastname }}</h6>
                        <h6 class="mb-0" v-if="row.contact">{{ row.contact.email }}</h6>
                      </div>
                    </div>

                  </div>

                </div>

              </div>
              <div slot="footer" class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
                <div class="">
                  <p class="card-category">
                    Showing {{ from + 1 }} to {{ to }} of {{ total }} entries
                  </p>
                </div>
                <base-pagination class="pagination-no-border" v-model="pagination.currentPage"
                  :per-page="pagination.perPage" :total="total">
                </base-pagination>
              </div>
            </card>
          </div>
        </div>
      </div>
      <div v-else>
        <div>
          <p>You don't seem to have any organisations linked to your account. Maybe consider Creating a new Organisation
            or Joining an existing one via the buttons below.</p>
          <br />
        </div>

        <div class="row justify-content-center vh-50">
          <section class="col-md-5">
            <base-button block type="success" class="mb-3 h-100" @click="onCreate">
              Create Organisation
            </base-button>
          </section>
          <section class="col-md-5">
            <base-button block type="success" class="mb-3 h-100" @click="joinOrgModal.joinOrganisation = true">
              Join Organisation
            </base-button>
            <modal :show.sync="joinOrgModal.joinOrganisation" body-classes="p-0"
              modal-classes="modal-dialog-centered modal-sm">
              <join-organisation :reset="joinOrgModal.reset" :on-save="onJoinOrganisation"
                :on-cancel="onCloseJoinModal">
              </join-organisation>
            </modal>
          </section>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { Table, TableColumn, Select, Option } from 'element-ui';
import { BasePagination } from 'src/components';
import { Modal } from 'src/components'
import Fuse from 'fuse.js';
import swal from 'sweetalert2';
import alerts from "@/store/modules/alerts";
import JoinOrganisation from 'src/tillered/forms/JoinOrganisation.vue';
import DotLoader from 'src/tillered/components/DotLoader.vue';
import model from "src/model";

const LOG_TAG = 'organisations';

export default {
  name: "organisations",
  components: {
    BasePagination,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    Modal,
    //OrganisationForm,
    JoinOrganisation,
    DotLoader
  },
  computed: {
    storeState() {
      return this.$store.state;
    },
    storeOrganisations() {
      return this.$store.state.organisations;
    },
    storeUsers() {
      return this.$store.state.users;
    },
    storeUserProfile() {
      return this.storeUsers.userProfile;
    },
    activeOrg() {
      return this.storeUserProfile.getCompany();
    },
    isLoading() {
      if (this.storeUserProfile.data === undefined) {
        return true;
      }
      return !this.storeUserProfile.data.active
    },
    storeOrganisationList() {
      return this.storeOrganisations.list;
    },
    memberName() {
      return this.$store.getters.userProfile.formattedFullName();
    },
    queriedData() {
      let result = this.mTableData;
      if (this.searchedData.length > 0) {
        result = this.searchedData;
      }
      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.searchedData.length > 0
        ? this.searchedData.length
        : this.mTableData.length;
    },
    searchStyle() {
      if (this.searchQuery !== '') {
        let result = this.fuseSearch.search(this.searchQuery);
        if (result.length == 0) {
          return {
            borderColor: 'var(--red)',
            color: 'var(--red)'
          }
        }
      }
      return {}
    },
  },
  data() {
    return {
      newOrgModal: {
        show: false,
      },
      joinOrgModal: {
        reset: 0,
        joinOrganisation: false,
      },
      alerts,
      pagination: {
        perPage: 50,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50, 100],
        total: 0
      },
      searchQuery: '',
      propsToSearch: ['name', 'contact.name', 'contact.firstname', 'contact.lastname', 'contact.email'],
      mTableData: [],
      searchedData: [],
      fuseSearch: null
    };
  },
  watch: {
    storeOrganisationList: function (value) {
      const vm = this;
      vm.mTableData = value;
    },
    searchQuery(value) {
      let result = this.tableData;
      let rv = [];
      if (value !== '') {
        result = this.fuseSearch.search(this.searchQuery);
        for (let i = 0; i < result.length; i++) {
          rv.push(result[i].item)
        }
      }
      this.searchedData = rv;
    }
  },
  mounted() {
    const vm = this;
    window.store.dispatch('removeAllAlerts');
    window.bloc.account.syncUserOrganisations(
      (data) => {
        vm.mTableData = vm.storeOrganisationList;
        // Fuse search initialization.
        this.fuseSearch = new Fuse(this.mTableData, {
          keys: this.propsToSearch, //['name', 'email']
          threshold: 0.2
        });
      }, (error) => { });

    window.bloc.account.syncUserContacts();
  },
  methods: {
    manageorg() {
      window.router.push({ name: 'Manage Active Org' });
    },
    onCreate(e) {
      window.router.push({ name: 'Create a New Org' });
    },
    handleLike(index, row) {
      swal.fire({
        title: `You liked ${row.name}`,
        buttonsStyling: false,
        icon: 'success',
        customClass: {
          confirmButton: 'btn btn-success btn-fill'
        }
      });
    },
    handleEdit(index, row) {
      swal.fire({
        title: `You want to edit ${row.name}`,
        buttonsStyling: false,
        customClass: {
          confirmButton: 'btn btn-info btn-fill'
        }
      });
    },
    handleDelete(index, row) {
      const vm = this;
      swal.fire({
        title: 'Are you sure?',
        text: `You won't be able to revert this!`,
        icon: 'warning',
        showCancelButton: true,
        customClass: {
          confirmButton: 'btn btn-success btn-fill',
          cancelButton: 'btn btn-danger btn-fill'
        },
        confirmButtonText: 'Yes, delete it!',
        buttonsStyling: false
      }).then(result => {
        if (result.value) {
          vm.onDeleteOrganisation(row);
        }
      });
    },
    deleteRow(row) {
      let indexToDelete = this.mTableData.findIndex(
        tableRow => tableRow.id === row.id
      );
      if (indexToDelete >= 0) {
        this.mTableData.splice(indexToDelete, 1);
      }
    },
    onDeleteOrganisation(data) {
      const vm = this;
      window.logger.d(LOG_TAG, 'onDeleteOrganisation', data);
      window.bloc.update.deleteOrganisation(
        data.getUuid(),
        (data) => {
          swal.fire({
            title: 'Success!',
            text: 'Organisation deleted successfully',
            icon: 'success',
            confirmButtonClass: 'btn btn-success btn-fill',
            buttonsStyling: false
          });
        },
        (error) => {
          window.store.dispatch('addError', {
            title: 'Error',
            message: model.error(error.error).formattedErrorMessage(),
            timeout: 3000
          }
          )
        }
      );
    },
    onJoinOrganisation(data) {
      const vm = this;
      window.logger.d(LOG_TAG, 'onJoinOrganisation', data);

      window.bloc.update.joinOrganisation(
        data.uuid,
        (data) => {
          window.store.dispatch('addSuccess', {
            title: 'Success',
            message: 'Requested to join organisation successful',
            timeout: 3000
          }
          );
          vm.joinOrgModal.joinOrganisation = false;
          vm.joinOrgModal.reset = Date.now();
        },
        (error) => {
          window.store.dispatch('addError', {
            title: 'Error',
            message: model.error(error.error).formattedErrorMessage(),
            timeout: 3000
          }
          )
        }
      );
    },
    onCloseJoinModal() {
      const vm = this;
      window.logger.d(LOG_TAG, 'onCancelModal');
      vm.joinOrgModal.joinOrganisation = false;
      vm.joinOrgModal.reset = Date.now();
    }
  },
};

</script>
<style>
.el-table .cell {
  word-break: normal;
}
</style>

