<template>
  <div class="content container-lg">
    <!-- Notifications container -->
    <div class="row">
      <div v-for="(alert, idx) in alerts.state.alerts" class="col-md-12 alert-container" :key="idx">
        <base-alert :type="alert.type" :icon="alert.icon" :storeindex="idx" dismissible>
          <strong>{{ alert.title }}:</strong> {{ alert.message }}
        </base-alert>
      </div>
    </div>

    <!-- Modals -->
    <modal :show.sync="mEditModal.show" body-classes="p-0" modal-classes="modal-dialog-centered modal-sm">
      <edit-network :reset="mEditModal.reset" :on-save="onNetworkEditSaveCb" :on-cancel="onCloseEditModalCb"
        :selected-network="mEditModal.network">
      </edit-network>
    </modal>

    <modal :show.sync="mAddModal.show" body-classes="p-0" modal-classes="modal-dialog-centered modal-md">
      <add-node :reset="mAddModal.reset" :on-save="onNodeAddCb" :on-cancel="onCloseNodeAddCb" :nodes="avaliableNodes">
      </add-node>
    </modal>

    <modal :show.sync="mDeactivateModal.show" body-classes="p-0" modal-classes="modal-dialog-centered modal-sm">
      <deactivate-node target="Network" :on-save="onDeactivateNetwork" :on-cancel="onCloseDeactivateNetworkModal">
      </deactivate-node>
    </modal>

    <!-- Body -->
    <div>
      <!-- Details -->
      <card>
        <div class="row justify-content-md-between justify-content-center">
          <div class="d-none d-md-flex col-md-3 text-center justify-content-center my-auto">
            <!-- Put Org Icon Here -->
            <div class="tim-icons icon-vector" style="font-size: 3.5em;"></div>
          </div>
          <div class="col-md-7 my-auto">
            <!-- Put Org Info Here -->
            <h4 slot="header" class="title mb-0">{{ networkName }}</h4>
            <b>{{ networkUuid }}</b>
          </div>
          <div class="col-md-2 my-auto">
            <base-button class="my-1" block type="success" @click="() => onNetworkEdit()">
              <i class="tim-icons icon-pencil"></i>
            </base-button>
            <base-button class="my-1" block type="danger" @click="() => { mDeactivateModal.show = true; }">
              <i class="tim-icons icon-trash-simple"></i>
            </base-button>
          </div>
        </div>

      </card>

      <!-- Nodes on Network -->
      <card>
        <div class="col-12 d-flex flex-column flex-md-row justify-content-md-between justify-content-center mb-4">
          <!-- Page Size -->
          <el-select class="col-md-2 col-12 p-0 my-2 select-primary pagination-select mx-md-0 mx-auto"
            v-model="pagination.perPage" placeholder="Per page">
            <el-option class="select-primary" v-for="item in pagination.perPageOptions" :key="item" :label="item"
              :value="item">
            </el-option>
          </el-select>

          <!-- Search Bar -->
          <div :class="[(TilleredOrg) ? 'col-md-8' : 'col-md-9', 'col-12', 'p-0', 'my-2']">
            <input type="text" :style="searchStyle" v-model="searchQuery" class="form-control" placeholder="Search">
          </div>

          <!-- New Node -->
          <div v-if="TilleredOrg" class="col-md-1 col-12 p-0 my-2">
            <base-button class="mt-0" block type="success" @click="mAddModal.show = true">
              <i class="tim-icons icon-simple-add"></i>
            </base-button>
          </div>
        </div>

        <!-- table -->
        <div>
          <!-- Header -->
          <div class="col-12 d-flex justify-content-between" style="border-bottom: solid 2px #525f7f;">
            <div class="m-2" :style="{ width: '10px' }"></div>
            <div class="col-5 col-xl-3 d-flex word-break">
              <h6>Name</h6>
            </div>
            <div class="col-2 col-xl-3 d-flex justify-content-center text-center word-break">
              <h6>Public/Private Ip</h6>
            </div>
            <div class="col-1 col-xl-2 d-flex text-center justify-content-center">
              <h6>Cloud</h6>
            </div>
            <div class="col-2">
              <h6>Disk Storage</h6>
            </div>
            <div class="col-1"></div>
          </div>

          <!-- Body -->
          <div v-if="queriedData.length">
            <div v-for="row in queriedData" :key="row.uuid" class="col-12 d-flex justify-content-between my-2"
              style="border-bottom: solid 1px #525f7f;">
              <!-- Node Status -->
              <StatusIndicator :stylecolor="getNodeStatus(row)" :toolmessage="getToolMesage(row)" />

              <!-- Node Type Icon + Name and Network -->
              <div class="col-5 col-xl-3 d-flex">
                <div class="d-flex m-2">
                  <i :id="`tooltip-target-${row.uuid}`" :class="['my-auto', 'tim-icons', getNodeType(row)]"
                    style="font-size: 1.8rem;"></i>
                </div>
                <div class="d-flex">
                  <div class="my-auto">
                    <p class="mb-0">{{ row.name }}</p>
                  </div>
                </div>
              </div>

              <!-- Node Public and Private IPs -->
              <div class="col-2 col-xl-3 d-flex justify-content-center text-center">
                <div class="my-auto">
                  <p>{{ row.public_ipv4 }}</p>
                  <p>{{ row.private_ipv4 }}</p>
                </div>
              </div>

              <!-- Node Cloud Provider -->
              <div class="col-1 col-xl-2 d-flex text-center justify-content-center">
                <p class="my-auto"><b>{{ row.cloud_provider }}</b></p>
              </div>

              <!-- Node Storage -->
              <div class="col-2">
                <div class="progress-container progress-default progress-sm">
                  <span v-if="row.disk_free_percentage > 20" class="progress-value">{{
                      getNodeStorageText(row)
                  }}%</span>
                  <span v-else class="progress-value" style="color: var(--danger)">{{
                      getNodeStorageText(row)
                  }}%</span>
                  <div class="progress">
                    <div role="progressbar" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"
                      class="progress-bar" :style="getNodeStorageWidth(row)"></div>
                  </div>
                </div>
              </div>

              <!-- Edit Button -->
              <div class="col-1 d-flex justify-content-center">
                <base-button @click.native="onNodeEdit(row)" class="my-auto edit btn-link" type="warning" size="sm"
                  icon>
                  <i class="tim-icons icon-pencil"></i>
                </base-button>
              </div>
            </div>
          </div>
          <div v-else-if="isDataLoading" class="col-12 row justify-content-center mt-5">
            <DotLoader />
          </div>
          <div v-else class="col-12 row justify-content-center mt-5">
          </div>
        </div>

        <div slot="footer" class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
          <div class="">
            <p class="card-category">
              Showing {{ from + 1 }} to {{ to }} of {{ total }} entries
            </p>
          </div>
          <base-pagination class="pagination-no-border" v-model="pagination.currentPage" :per-page="pagination.perPage"
            :total="total">
          </base-pagination>
        </div>
      </card>
    </div>
  </div>
</template>
<script>

import { BasePagination } from 'src/components';
import Fuse from 'fuse.js';

import EditNetwork from 'src/tillered/forms/EditNetwork.vue';
import AddNode from 'src/tillered/forms/AddNode.vue';
import { Modal } from 'src/components'
import DotLoader from 'src/tillered/components/DotLoader.vue';
import StatusIndicator from 'src/tillered/components/StatusIndicator.vue';
import DeactivateNode from 'src/tillered/forms/DeactivateNode';

import BaseAlert from "@/components/BaseAlert";
import alerts from "@/store/modules/alerts";
import Card from '../../components/Cards/Card.vue';
import model from "src/model";

export default {
  name: "NetworkEdit",
  components: {
    BasePagination,
    Card,
    Modal,
    EditNetwork,
    AddNode,
    DotLoader,
    StatusIndicator,
    DeactivateNode
  },
  data() {
    return {
      alerts,
      selectedNetworkUuid: '',
      selectedNetwork: model.network(),
      searchedData: [],
      fuseSearch: null,
      loadingNetData: true,
      pagination: {
        perPage: 50,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50, 100],
        total: 0
      },
      propsToSearch: ['uuid', 'name', 'private_ipv4', 'public_ipv4', 'cloud_provider'],
      searchQuery: '',
      mAddModal: {
        reset: 0,
        show: false,
      },
      mEditModal: {
        reset: 0,
        show: false,
        network: {
          uuid: "",
          name: "",
        },
        close() {
          this.show = false;
          this.network = {
            uuid: "",
            name: "",
          };
          this.reset = Date.now();
        }
      },
      mDeactivateModal: {
        show: false
      }
    };
  },
  computed: {
    // Store Properties
    storeState() {
      return this.$store.state;
    },
    storeNetworks() {
      return this.$store.state.networks;
    },
    storeNodes() {
      return this.$store.state.nodes;
    },
    storeNetworksList() {
      return this.storeNetworks.list;
    },
    storeNodesList() {
      return this.storeNodes.list;
    },
    storeUsers() {
      return this.$store.state.users;
    },
    storeUserProfile() {
      return this.storeUsers.userProfile;
    },

    // Network Properties
    networkName() { return this.selectedNetwork.name; },
    networkUuid() { return this.selectedNetwork.uuid; },
    networkNodes() {
      if (this.selectedNetwork.nodes === undefined) return [];
      return this.storeNodesList.filter((node) => this.selectedNetwork.nodes.includes(node.uuid));
    },
    networkOrg() {
      return this.selectedNetwork.organisation;
    },
    networkContact() {
      return this.selectedNetwork.organisation.contact;
    },
    avaliableNodes() {
      return this.storeNodesList.filter((node) => !node.networks)
    },

    TilleredOrg() {
      return "Tillered Limited" === this.storeUserProfile.getCompanyName();
    },

    // Table Pagination
    queriedData() {
      let result = this.networkNodes;
      if (this.searchedData.length > 0) {
        result = this.searchedData;
      }
      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.searchedData.length > 0
        ? this.searchedData.length
        : this.networkNodes.length;
    },
    isDataLoading() {
      return this.loadingNetData;
    },
    searchStyle() {
      if (this.searchQuery !== '') {
        let result = this.fuseSearch.search(this.searchQuery);
        if (result.length == 0) {
          return {
            borderColor: 'var(--red)',
            color: 'var(--red)'
          }
        }
      }
      return {}
    },
  },
  mounted() {
    window.store.dispatch('removeAllAlerts');

    this.selectedNetworkUuid = this.$route.params.uuid;
    const selected = this.storeNetworksList.find((net) => net.getUuid() === this.selectedNetworkUuid);

    if (selected) {
      this.selectedNetwork = selected;
      window.bloc.account.syncUserNodes(() => {
        this.loadingNetData = false;
      });

    } else {
      this.setGetNetwork(this.selectedNetworkUuid);
    }


  },
  methods: {

    // Callbacks
    onNetworkEdit() {
      this.mEditModal.network = {
        uuid: this.networkUuid,
        name: this.networkName,
      }
      this.mEditModal.show = true;
    },
    onNetworkEditSaveCb(data) {
      const vm = this;
      window.logger.d('onUpdateNetwork', data);
      if (data) {
        window.bloc.update.updateNetwork(
          data,
          (data) => {
            window.store.dispatch('addSuccess', {
              title: 'Success',
              message: 'Network updated successfully',
              timeout: 3000
            }
            )
            vm.mTableData = data;
            vm.mEditModal.close();
            this.setGetNetwork(this.selectedNetworkUuid);
          },
          (error) => {
            window.store.dispatch('addError', {
              title: 'Error',
              message: model.error(error.error).formattedErrorMessage(),
              timeout: 3000
            }
            )
          }
        );
      } else {
        vm.mEditModal.close();
      }
    },
    onCloseEditModalCb() {
      const vm = this;
      window.logger.d('onCancelEditModal');
      vm.mEditModal.close();
    },
    onNodeAddCb(data) {
      window.logger.d('submitted node adding to net', data);
      data.forEach((nodeUuid) => {
        window.webapi.network.addNode(this.selectedNetworkUuid, nodeUuid,
          () => {
            window.store.dispatch('addSuccess', {
              title: 'Success',
              message: 'Node ' + nodeUuid + ' added to ' + this.networkName,
              timeout: 3000
            });
            this.setGetNetwork(this.selectedNetworkUuid);
            window.bloc.account.syncUserNodes();
          },
          (error) => {
            window.store.dispatch('addError', {
              title: 'Error',
              message: model.error(error.error).formattedErrorMessage(),
              timeout: 3000
            });
          })
      });
      this.mAddModal.show = false;
    },
    onCloseNodeAddCb() {
      this.mAddModal.show = false;
    },
    onNodeEdit(row) {
      window.router.push(
        {
          name: 'Node Details',
          params: { uuid: row.getUuid() }
        }
      );
    },
    onDeactivateNetwork() {
      const vm = this;
      vm.mDeactivateModal.show = false;
      
      window.webapi.network.deactivateNetwork(
        vm.selectedNetworkUuid,
        () => {
          window.store.dispatch('addSuccess', {
            title: 'Success',
            message: 'Network deactivated successfully',
            timeout: 3000
          }
          )
          window.router.push({ name: 'Networks' });
        },
        (error) => {
          window.store.dispatch('addError', {
            title: 'Error',
            message: error.error.message,
            timeout: 3000
          }
          )
        }
      );
    },
    onCloseDeactivateNetworkModal() {
      const vm = this;
      vm.mDeactivateModal.show = false;
    },

    // Data
    setGetNetwork(uuid) {
      const vm = this;
      vm.loadingNetData = true;
      window.webapi.network.getNetwork(
        uuid,
        (data) => {
          vm.selectedNetwork = data.data;

          window.bloc.account.syncUserNodes(() => {
            vm.loadingNetData = false;
          });
        }, (error) => {
          console.log(error)
        }
      )
    },

    // Table Properties
    getNodeType(row) {
      return model.getNodeIcon(row.type);
    },
    getNodeStatus(row) {
      return model.getNodeStatus(row);
    },
    getToolMesage(row) {
      return model.getNodeStatusText(row);
    },
    getNodeStorageText(row) {
      return (100 - row.disk_free_percentage).toFixed(2)
    },
    getNodeStorageWidth(row) {
      if (100 - row.disk_free_percentage > 80) {
        return "width: " + this.getNodeStorageText(row) + "%; background-color: var(--danger);"
      }
      return "width: " + this.getNodeStorageText(row) + "%"
    },
  },
  watch: {
    searchQuery(value) {
      let result = this.networkNodes;
      let rv = [];
      if (value !== '') {
        result = this.fuseSearch.search(this.searchQuery);
        for (let i = 0; i < result.length; i++) {
          rv.push(result[i].item)
        }
      }
      this.searchedData = rv;
    },
    networkNodes(value) {
      this.fuseSearch = new Fuse(value, {
        keys: this.propsToSearch,
        threshold: 0.2
      });
    },
    'storeUserProfile.data.activeOrganisation'(value) {
      this.setGetNetwork(this.selectedNetworkUuid);
    }
  },
}

</script>