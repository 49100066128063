<template>
  <card
    type="secondary"
    class="border-0 mb-0">
    <form @submit.prevent="onCreateNode">
      <h4 slot="header" class="modal-title" id="modal-title-new">Add a New Tillered Node</h4>
      <div class="mt-2"></div>
      <div class="row">
        <div class="col-md-6">
          <div class="col-md-12">
            <div class="row">
              <div class="col-md-12">
                <base-input label="Node Type">
                  <el-select
                    class="select-primary"
                    placeholder="Select Node Type"
                    v-model="mNode.type.selected">
                    <el-option v-for="(option, index) in mNode.type.options"
                               class="select-primary"
                               :value="option.value"
                               :label="option.label"
                               :key="index">
                    </el-option>
                  </el-select>
                </base-input>
                <base-input label="Network">
                  <el-select
                    class="select-primary"
                    placeholder="Select Network"
                    v-model="mNode.network.selected">
                    <el-option v-for="(option, index) in mNode.network.options"
                               class="select-primary"
                               :value="option.value"
                               :label="option.label"
                               :key="index">
                    </el-option>
                  </el-select>
                </base-input>
                <base-input label="Node Platform">
                  <el-select
                    class="select-primary"
                    :clearable="true"
                    placeholder="Select Node Platform"
                    v-model="mNode.platform.selected">
                    <el-option v-for="(option, index) in mNode.platform.options"
                               class="select-primary"
                               :value="option.value"
                               :label="option.label"
                               :key="index">
                    </el-option>
                  </el-select>
                </base-input>
                <div>
                  <base-input
                    type="text"
                    label="Instance Identifier"
                    v-model="mNode.instanceIdentifier"
                    :required="true"
                  >
                  </base-input>
                  <base-input
                    type="text"
                    label="Validation IP Address"
                    v-model="mNode.validationIP"
                    :required="true"
                  >
                  </base-input>
                </div>
                <span v-if="mNode.remoteValidationLoading"><i  class="fas fa-spinner fa-spin"></i> Verifying Node Instance please wait</span>
                <base-input
                  type="text"
                  label="Name"
                  :required="true"
                  v-model="mNode.name"
                  v-if="mNode.remoteValidation"
                >
                </base-input>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="row">
            <div class="col-md-12">
              <div v-if="this.mNode.platform.selected === null || this.mNode.platform.selected ===''" class="content">
                <h2>New Node Information</h2>
                <div class="whitetext">The process of adding a node requires you to follow particular steps, depending on the platform you wish to use to host your node. There are 3 node types. Entry, Exit, and Full Node.</div>
                <br />
                <ul>
                  <li>TEN: Tillered Entry Node to get data into your Tillered network.</li>
                  <li>CEN: Cloud Exit Node this is where data will leave the Tillered network, and NATing will occur.</li>
                  <li>FTN: Full Tillered Node is where data can enter and exit the Tillered network.</li>
                </ul>
              </div>
              <div v-if="this.mNode.platform.selected === 'AWS'" class="content">
                <img src="/img/vendor/aws-logo.png" /><br />
                <div class="whitetext">To order an Amazon Node you need to log into AWS Management Console and choose EC2 -> Instances -> Launch Instances</div>
                <ul>
                  <li>Provide a name for the Instance. Then from the 'Application and OS Images (AMI) Section Search for 'Tillered' and select Enter.</li>
                  <li>Create the Image using Tillered CS9 Aarch 64</li>
                  <li>Note: Recommended instances are amazon c6gn (10Gbit = c6gn.medium, c6gn.large, c6gn.xlarge, c6gn.2xlarge). And create the Amazon Image.</li>
                  <li>Enter the i-{xxxxxxxx} instance id into the Instance ID field on this form.</li>
                  <li>Enter either the Private IP address of the machine OR the Public IP where the NATing will be done.</li>
                  <li>Once this data is Entered Correctly You will be able to Enter a Name for the Node and choose Save.</li>
                </ul>
                <img src="/img/tillered/aws_ec2_screenshot.png" />
              </div>
              <div v-if="this.mNode.platform.selected === 'AZURE'" class="content">
                <img src="/img/vendor/azure120.png" /><br />
                <div class="whitetext">Order a machine on the Microsoft Marketplace.</div>
                <ul>
                  <li>Log Into Azure. Choose Virtual Machines -> Create a Virtual Machine. Where it says 'Images' select 'See all Images' this will bring you to the marketplace. Where you can search for 'Tillered'.</li>
                  <li>Current supported architecture is x86</li>
                  <li>Once the machine is booted the Instance ID needs to be entered the same as the 'Computer name' field in azure. e.g. {uuid}.tillered.net</li>
                  <li>Enter either the Private IP address of the machine OR the Public IP where the NATing will be done.</li>
                  <li>Once this data is Entered Correctly You will be able to Enter a Name for the Node and choose Save.</li>
                </ul>
                <img src="/img/tillered/azure-info.png" />
              </div>
              <div v-if="this.mNode.platform.selected === 'HYPERV'" class="content">
                <img src="/img/vendor/5B5EFCA7-DF8C-4123-AF48-FA67F883AD2B.jpeg" /><br />
                <ul>
                  <li>Steps to setup Tillered Node on Hyper-V</li>
                  <li>Download the Hyper-V disk image from <a href="#">here</a>.</li>
                  <li>Ensure to install a NEW HyperV Server using the associated xxx file. </li>
                  <li>Under Virtual Switch Properties Ensure you check "Allow management operating system to share this network adapter"</li>
                  <li>Under Network Adapter Advanced Features you need to check to box to enable "Mac address spoofing"</li>
                  <li>On the login screen in the console you please enter the last 12 hex characters (see image) in the Instance ID box.</li>
                  <li>Enter either the Private IP address of the machine (shown in image) OR the Public Internet IP where the NATing takes place.</li>
                  <li>Once this data is Entered Correctly You will be able to Enter a Name for the Node and choose Save.</li>
                </ul>
                <img src="/img/tillered/tillered_host.png" />
              </div>
              <div v-if="this.mNode.platform.selected === 'VMWARE'" class="content">
                <img src="/img/vendor/vmware.png" /><br />
                <ul>
                  <li>Steps to setup VMware Tillered Node</li>
                  <li>Download the VMware disk image from <a href="https://tillered-packages.s3.ap-southeast-2.amazonaws.com/vmware/Tillered-RH9-20220610.zip">here</a>.</li>
                  <li>Ensure to install a NEW VMware vmdk using the associated .ovf file. </li>
                  <li>In the Port Group Properties for the VM under Security. Ensure Promiscuous mode is set to 'Accept'. Ensure Mac Address Changes is set to 'Accept'. And ensure Forged transmits are set to 'Accept'</li>
                  <li>On the login screen in the console you please enter the last 12 hex characters (see image) in the Instance ID box.</li>
                  <li>Enter either the Private IP address of the machine (shown in image) OR the Public Internet IP where the NATing takes place.</li>
                  <li>Once this data is Entered Correctly You will be able to Enter a Name for the Node and choose Save.</li>
                </ul>
                <img src="/img/tillered/tillered_host.png" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="mt-4"></div>
      <div class="d-flex flex-row justify-content-between">
        <base-button native-type="submit" type="primary" class="btn-fill" :disabled="!isValid">
          Save
        </base-button>
        <base-button type="secondary" class="btn-fill" v-on:click.prevent="onCancel">
          Back
        </base-button>
      </div>
    </form>
  </card>
</template>
<script>

import model from "../../model";

const LOG_TAG = 'create_node';

export default {
  props: {
    onSave: {
      type: Function,
      required: true,
    },
    onCancel: {
      type: Function,
      required: true,
    },
    reset: {
      type: Number,
      default: function() {
        return 0;
      }
    }
  },
  data() {
    return {
      mNode: {
        name: '',
        platform: {
          selected: null,
          options: [{label: 'Amazon AWS AMI', value: 'AWS'}, {label: 'Microsoft Azure Marketplace', value: 'AZURE'}, {label: 'Microsoft Hyper V Hypervisor', value: 'HYPERV'}, {label: 'VMware ESX', value: 'VMWARE'}]
        },
        instanceIdentifier: null,
        validationIP: null,
        network: {
          selected: null,
          options: []
        },
        type: {
          selected: null,
          options: [
            {label: 'Tillered Entry Node (TEN)', value: 'TEN'},
            {label: 'Tillered Cloud Exit Node (CEN)', value: 'CEN'},
            {label: 'Full Tillered Node (FTN)', value: 'FTN'},
          ]
        },
        valid: false,
        remoteValidation: false,
        remoteValidationLoading: false,
        validate() {
          this.valid =
            this.name &&
            this.platform.selected &&
            this.type.selected &&
            this.network.selected &&
            this.validateInstanceId() && this.validateIP() && this.remoteValidation && this.name;
        },
        reset() {
          this.name = '';
          this.platform.selected = null;
          this.type.selected = null;
          this.valid = false;
          this.remoteValidation = false;
          this.remoteValidationLoading = false;
        },
        validateInstanceId() {
          if (!this.platform) {
            return false
          }
          switch (this.platform.selected) {
            case "AWS":
              const awsRe = /^i\-[0-9a-f]{17}$/g;
              return awsRe.exec(this.instanceIdentifier) !== null
            case "AZURE":
              const azureRe = /^[0-9a-z]{8}\-[0-9a-z]{4}\-[0-9a-z]{4}\-[0-9a-z]{4}\-[0-9a-z]{12}\.tillered\.net$/g
              return azureRe.exec(this.instanceIdentifier) !== null
            case "VMWARE":
            case "HYPERV":
              const vmwHyperVRe = /^[0-9a-z]{12}$/g
              return vmwHyperVRe.exec(this.instanceIdentifier) !== null
            default:
              return false
          }
        },
        validateIP() {
          if (!this.validationIP) {
            return false
          }
          return /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(this.validationIP)
        },
        updateNetworks(list) {
          this.network.options = list.map((data) => {
            return {
              label: data.name,
              value: data.getUuid()
            };
          });
        },
      },
    };
  },
  computed: {
    nodeCreationPayload() {
      let payload = {
          "name": this.mNode.name,
          "organisation": this.$store.getters.userProfile.getCompany().uuid,
          "type": this.mNode.type.selected,
          "network": this.mNode.network.selected,
          "platform": {
            "type": this.mNode.platform.selected,
            "instanceIdentifier": this.mNode.instanceIdentifier,
            "validationIP": this.mNode.validationIP
          }
        };
      return payload;
    },
    storeState() {
      return this.$store.state;
    },
    storeNetworks() {
      return this.$store.state.networks;
    },
    storeNetworksList() {
      return this.storeNetworks.list;
    },
    isValid() {
      return this.mNode.valid;
    },
  },
  watch: {
    reset: function(value) {
      const vm = this;
      window.logger.d(LOG_TAG, 'watch:reset', value);
      vm.mNode.reset();
    },
    storeNetworksList: function(value) {
      const vm = this;
      window.logger.d(LOG_TAG, 'watch:storeNetworksList', value);
      vm.mNode.updateNetworks(value);
    },
    'mNode.name': function(value) {
      const vm = this;
      vm.mNode.validate();
    },
    'mNode.network.selected': function(value) {
      const vm = this;
      vm.mNode.validate();
    },
    'mNode.type.selected': function(value) {
      const vm = this;
      vm.mNode.validate();
    },
    'mNode.instanceIdentifier': function (value) {
       const vm = this;
       if(vm.mNode.validateInstanceId() && vm.mNode.validateIP()) {
         this.remotelyValidateInput(vm.nodeCreationPayload)
       }
    },
    'mNode.validationIP': function (value) {
      const vm = this;
      if( vm.mNode.validateInstanceId() && vm.mNode.validateIP() ) {
        this.remotelyValidateInput(vm.nodeCreationPayload)
      }
    },
    'mNode.validatedOk': function (value) {
      if (value == true) {
        //todo
      }
    },
    storeNetworks: function(value) {
      const vm = this;
      window.logger.d(LOG_TAG, 'watch:storeNetworks', value);
      vm.mNode.updateNetworks(value);
    }
  },
  mounted() {
    window.bloc.account.syncUserNetworks(
      (data) => { },
      (error) => { }
    );
  },
  methods: {
    onCreateNode(data) {
        const vm = this;
        vm.onSave(vm.nodeCreationPayload);
    },
    remotelyValidateInput(payload) {
      try {
        this.mNode.remoteValidationLoading = true
        window.webapi.node.validateRemote(payload,  (data) => {
          if (data.data.validatedOk === true) {
            this.mNode.remoteValidation = true
          }
        }, (error) => {
          this.mNode.remoteValidation = false
        })
      } catch (e) {
        window.logger.d('validate_node', 'method:remoteValidateInput', e);
      } finally {
        this.mNode.remoteValidationLoading = false
      }
    }
  },
};
</script>
<style>
.whitetext {
  color: #FFF;
}
</style>
