<template>
    <div>
        <div>
            <label>Contact Physical Address *</label>
            <div class="col-md-12 px-0">
                <base-input type="text" placeholder="Address Line 1" v-model="addr.physicalAddress.addr1" required noAutocomplete />
                <base-input type="text" placeholder="Address Line 2" v-model="addr.physicalAddress.addr2" noAutocomplete />
            </div>
            <div class="row px-0">
                <div class="col-md-6">
                    <base-input type="text" placeholder="City" v-model="addr.physicalAddress.city" required noAutocomplete />
                </div>
                <div class="col-md-6">
                    <base-input type="text" placeholder="State/Province/Region" v-model="addr.physicalAddress.state"
                        required noAutocomplete />
                </div>
            </div>
            <div class="row px-0">
                <div class="col-md-6">
                    <base-input type="text" placeholder="Postal / Zip Code" v-model="addr.physicalAddress.postal"
                        required noAutocomplete />
                </div>
                <div class="col-md-6">
                    <select class="form-group form-control" placeholder="Country" v-model="addr.physicalAddress.country"
                        @focus="touchedPhysicalCountry = true"
                        :style="{ 'border-color': (touchedPhysicalCountry && addr.physicalAddress.country == '') ? 'var(--danger)' : '' }">
                        <option value="" disabled hidden>Country</option>
                        <option style="color: #000000" v-for="country in countries" :value="country.code" :key="country.id">
                            {{ country.name }}
                        </option>
                    </select>
                </div>
            </div>
        </div>
        <div>
            <label>Contact Postal Address *</label>

            <div>
                <input type="checkbox" id="checkbox" class="mr-2" v-model="addr.postalIsPhysical">
                <label for="checkbox">Same as Physical Address?</label>
            </div>

            <div class="col-md-12 px-0">
                <base-input type="text" placeholder="Address Line 1" v-model="addr.postalAddress.addr1"
                    :required="!addr.postalIsPhysical" :disabled="addr.postalIsPhysical" noAutocomplete/>
                <base-input type="text" placeholder="Address Line 2" v-model="addr.postalAddress.addr2"
                    :disabled="addr.postalIsPhysical" noAutocomplete/>
            </div>
            <div class="row px-0">
                <div class="col-md-6">
                    <base-input type="text" placeholder="City" v-model="addr.postalAddress.city"
                        :required="!addr.postalIsPhysical" :disabled="addr.postalIsPhysical" noAutocomplete/>
                </div>
                <div class="col-md-6">
                    <base-input type="text" placeholder="State/Province/Region" v-model="addr.postalAddress.state"
                        :required="!addr.postalIsPhysical" :disabled="addr.postalIsPhysical" noAutocomplete/>
                </div>
            </div>
            <div class="row px-0">
                <div class="col-md-6">
                    <base-input type="text" placeholder="Postal / Zip Code" v-model="addr.postalAddress.postal"
                        :required="!addr.postalIsPhysical" :disabled="addr.postalIsPhysical" noAutocomplete/>
                </div>
                <div class="col-md-6">
                    <select class="form-group form-control" placeholder="Country" v-model="addr.postalAddress.country"
                        :disabled="addr.postalIsPhysical" @focus="touchedPostalCountry = true"
                        :style="{ 'border-color': (touchedPostalCountry && addr.postalAddress.country == '' && !addr.postalIsPhysical) ? 'var(--danger)' : '' }">
                        <option value="" disabled hidden>Country</option>
                        <option style="color: #000000" v-for="country in countries" :value="country.code" :key="country.id">
                            {{ country.name }}
                        </option>
                    </select>
                </div>
            </div>
        </div>
    </div>
</template>
<script>


export default {
    data() {
        return {
            countries: [],
            addr: {
                postalIsPhysical: false,
                physicalAddress: {
                    addr1: '',
                    addr2: '',
                    city: '',
                    state: '',
                    postal: '',
                    country: 'NZ',
                },
                postalAddress: {
                    addr1: '',
                    addr2: '',
                    city: '',
                    state: '',
                    postal: '',
                    country: 'NZ',
                },
            },
            touchedPhysicalCountry: false,
            touchedPostalCountry: false,
        };
    },
    mounted() {
        window.webapi.profile.getCountryList(
            (data) => {
                this.countries = data.data;
            },
            (error) => {
                console.log(error);
            }
        )
    },
    methods: {
        onDataUpdate(val) {
            let addr = {
                physical: val.physicalAddress,
                postal: val.postalAddress
            }
            if (val.postalIsPhysical) {
                addr.postal = addr.physical;
            }

            console.log(val);

            // Emit new data to parent
            this.$emit('input', addr);
        }
    },
    watch: {
        // Check if any data has changed
        addr: {
            handler(val) {
                if (val) {
                    this.onDataUpdate(val);
                }
            },
            deep: true,
        }
    }
}
</script>
