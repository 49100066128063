<template>
  <card type="secondary" class="border-0 mb-0">
    <form @submit.prevent="onInviteOrganisation">

      <h6 slot="header" class="modal-title" id="modal-title-new">Invite Users</h6>
      <div class="mt-2"></div>

      <div>
        <div v-for="(item, key) in mUsers.userList" :key="key" class="d-flex justify-content-between">
          <p class="text-primary my-auto">{{item}}</p>
          <p class="tim-icons icon-simple-remove text-danger my-auto" style="cursor: pointer;"
            @click="() => mUsers.removeUser(item)"></p>
        </div>
      </div>

      <div class="mt-2"></div>
      <div class="input-group mb-3">
        <input type="text" v-model="mUsers.currentUser" class="form-control" placeholder="Recipient's username"
          aria-label="Recipient's username" aria-describedby="basic-addon2" style="height: 100%;" @keyup.enter="() => mUsers.addUser(mUsers.currentUser)">
        <div class="input-group-append">
          <button class="btn btn-outline-secondary m-0 tim-icons icon-simple-add"
            @click="() => mUsers.addUser(mUsers.currentUser)" :disabled="!isValid" type="button" />
        </div>
      </div>

      <div class="mt-4"></div>
      <div class="d-flex flex-row justify-content-between">
        <base-button native-type="submit" type="primary" class="btn-fill" :disabled="mUsers.userList.length < 1">
          Invite
        </base-button>
        <base-button type="secondary" class="btn-fill" v-on:click.prevent="onCancel">
          Cancel
        </base-button>
      </div>

    </form>
  </card>
</template>
<script>

import model from 'src/model';
const LOG_TAG = 'invite_user';

export default {
  props: {
    onInvite: {
      type: Function,
      required: true,
    },
    onCancel: {
      type: Function,
      required: true,
    },
    reset: {
      type: Number,
      default: function () {
        return 0;
      }
    }
  },
  data() {
    return {
      isActive: true,
      mUsers: {
        currentUser: '',
        userList: [],
        softReset() {
          this.currentUser = '';
        },
        /**
         * Add a new User to the modal list
         * @param {string} email A user's email to add
         */
        addUser(email) {
          if (!model.validateEmail(email)) return; // Invalid Email (Throw an error)
          this.userList.push(email.trim());
          this.softReset();
        },
        removeUser(email) {
          this.userList = this.userList.filter((x) => x.trim() !== email.trim());
        },
        reset() {
          this.softReset();
          this.userList = [];
        },
        export() {
          let payload = {
            "userInviteEmailList": this.userList,
          };
          return payload;
        }
      }
    };
  },
  computed: {
    isValid() {
      return model.validateEmail(this.mUsers.currentUser) && !this.mUsers.userList.includes(this.mUsers.currentUser);
    },
  },
  watch: {
    reset: function (value) {
      const vm = this;
      window.logger.d(LOG_TAG, 'watch:reset', value);
      vm.mUsers.reset();
    },
  },
  methods: {
    onInviteOrganisation() {
      const vm = this;
      vm.onInvite(vm.mUsers.export());
      vm.mUsers.reset()
    }
  },
};
</script>
<style>

</style>
  