<template>
  <div class="container-lg">
    <div v-if="isLoading" class="row">
      <div class="col-12 row justify-content-center mt-5">
        <DotLoader />
      </div>
    </div>
  
    <div v-else class="row">
      <!-- Big Chart -->
      <div class="col-12">
        <traffic-chart :rawChartData="dashboardData" />
      </div>
  
      <!-- Stats Cards -->
      <div class="col-lg-3 col-md-6" v-for="card in statsCards" :key="card.id">
        <stats-card :title="card.title" :sub-title="card.subTitle" :type="card.type" :icon="card.icon">
          <!-- <div slot="footer" v-html="card.footer"></div> -->
        </stats-card>
      </div>
  
      <!-- Member List -->
      <div class="col-md-6" :class="{ 'text-right': isRTL }">
        <card>
          <h5 class="card-category" style="font-size: 1.2rem">Members</h5>
          <div v-if="memberList" style="height: 350px; overflow-y: auto; overflow-x: hidden;">
            <div class="m-2" v-for="member in memberList" :key="member.uuid">
              <div class="row pt-2" style="border-top: 2px solid var(--default)">
                <div class="col-sm-6">
                  <p>{{ member.user ? member.user.name : "Pending..." }}</p>
                </div>
                <div class="col-sm-6 text-right">
                  <p>{{ member.email }}</p>
                </div>
              </div>
              <p :style="[chipStyle, memberChip(member.status)]">{{ member.status }}</p>
            </div>
          </div>
          <div v-else>
            No Data
          </div>
        </card>
      </div>
  
      <!-- Network List -->
      <div class="col-md-6" :class="{ 'text-right': isRTL }">
        <card>
          <h5 class="card-category" style="font-size: 1.2rem">Networks</h5>
          <div v-if="networkList" style="height: 350px; overflow-y: auto; overflow-x: hidden;">
            <div class="m-2" v-for="network in networkList" :key="network.uuid">
              <div class="row pt-2" style="border-top: 2px solid var(--default)">
                <div class="col-sm-10">
                  <p>{{ network.name }}</p>
                </div>
                <div class="col-sm-2 text-right">
                  <p>{{ network.nodeCount }}</p>
                </div>
              </div>
            </div>
          </div>
          <div v-else>
            No Data
          </div>
        </card>
      </div>
  
    </div>
  </div>
</template>
<script>
import StatsCard from 'src/components/Cards/StatsCard';
import DotLoader from 'src/tillered/components/DotLoader.vue';

import TrafficChart from 'src/tillered/components/dashboard/TrafficChart.vue';

export default {
  components: {
    StatsCard,
    DotLoader,
    TrafficChart,
  },
  data() {
    return {
      rawDashboardData: {},
      orgMembers: [],
      rawNetworkData: [],
      chipStyle: {
        borderRadius: '10px',
        textAlign: 'center',
        fontWeight: 'bold',
        display: 'inline-block',
        padding: '0px 10px',
      }
    };
  },
  computed: {
    statsCards() {
      return [
        {
          id: 0,
          title: `${this.totalMembers}`,
          subTitle: 'Total Members',
          type: 'info',
          icon: 'tim-icons icon-single-02',
          footer: `<i class="tim-icons icon-trophy"></i> Total Active Members in Organisation: <b>${this.$store.getters.userProfile.getCompanyName()}</b>`
        },
        {
          id: 1,
          title: `${this.totalNetworks}`,
          subTitle: 'Total Networks',
          type: 'success',
          icon: 'tim-icons icon-world',
          footer: `<i class="tim-icons icon-refresh-01"></i> Total Managed Networks for: <b>${this.$store.getters.userProfile.getCompanyName()}</b>`
        },
        {
          id: 2,
          title: `${this.totalNodes}`,
          subTitle: 'Total Nodes',
          type: 'primary',
          icon: 'tim-icons icon-tv-2',
          footer: '<i class="tim-icons icon-sound-wave"></i></i> Total nodes from all networks'
        },
        {
          id: 3,
          title: `${this.totalNotifications}`,
          subTitle: 'Notifications',
          type: 'danger',
          icon: 'tim-icons icon-chat-33',
          footer: '<i class="tim-icons icon-watch-time"></i> In the last 24 hours'
        }
      ]
    },
    enableRTL() {
      return this.$route.query.enableRTL;
    },
    isRTL() {
      return this.$rtl.isRTL;
    },
    storeNetworks() {
      return this.$store.state.networks;
    },
    storeNetworksList() {
      return this.storeNetworks.list;
    },
    storeUsers() {
      return this.$store.state.users;
    },
    storeUserProfile() {
      return this.storeUsers.userProfile;
    },
    activeOrg() {
      return this.storeUserProfile.getCompany();
    },
    isLoading() {
      if (this.storeUserProfile.data === undefined) {
        return true;
      }
      return false; // !this.storeUserProfile.data.active
    },
    dashboardData() {
      return this.rawDashboardData;
    },
    totalMembers() {
      return this.memberList.length;
    },
    totalNetworks() {
      return this.networkList.length;
    },
    totalNodes() {
      return this.networkList.reduce((accumulator, network) => {
        return accumulator + network.nodeCount;
      }, 0)
    },
    totalNotifications() {
      if (!this.dashboardData.org_stats) return 0;
      return this.dashboardData.notifications.length;
    },
    memberList() {
      return this.orgMembers.filter(member => member.active);
    },
    networkList() {
      return this.rawNetworkData.filter(network => network.active);
    }
  },
  methods: {
    memberChip(status) {
      switch (status) {
        case 'ADMIN': return { backgroundColor: 'var(--info)' };
        case 'MEMBER': return { backgroundColor: 'var(--success)' };
        case 'INVITED': return { backgroundColor: 'var(--warning)' };
      }
      return { backgroundColor: 'var(--danger)' }
    },
    fetchMemberData(value) {
      if (value) {
        // Get all members from organisation
        window.webapi.organisation.getMembers(
          value.uuid, // Active Org
          (data) => this.orgMembers = data.data, // Member Data
          () => { }
        );
      }
    },
    fetchNetworkData() {
      window.bloc.account.syncUserNetworks(
        () => { this.rawNetworkData = this.storeNetworksList; }
      );
    },
  },
  mounted() {
    this.i18n = this.$i18n;
    if (this.enableRTL) {
      this.i18n.locale = 'ar';
      this.$rtl.enableRTL();
    }

    window.store.dispatch('removeAllAlerts');

    // Fetch Dashboard Data
    window.webapi.organisation.getDashboard(
      (data) => { this.rawDashboardData = data.data; }
    );

    if (this.activeOrg){
      this.fetchMemberData(this.activeOrg);
      this.fetchNetworkData();
    }
  },
  beforeDestroy() {
    if (this.$rtl.isRTL) {
      this.i18n.locale = 'en';
      this.$rtl.disableRTL();
    }
  },
  watch: {
    storeUserProfile: {
      handler(newValue) {
        if (!newValue.hasCompany()) {
          this.$router.push({ name: 'Organisations' });
        }
      },
      deep: true,
    },
    activeOrg: function (value) {
      const vm = this;

      vm.fetchMemberData(value);
      vm.fetchNetworkData();
    }
  }
};
</script>
<style>

</style>
