<template>
  <div class="container-lg">
    <div class="row">
      <div v-for="(alert, idx) in alerts.state.alerts" class="col-md-12 alert-container" :key="idx">
        <base-alert :type="alert.type" :icon="alert.icon" :storeindex="idx" dismissible>
          <strong>{{ alert.title }}:</strong> {{ alert.message }}
        </base-alert>
      </div>
    </div>
    <div v-if="activeOrg">
      <card>
        <div class="row">
          <div class="d-none d-md-flex col-md-3 col-xl-2 text-center justify-content-center">
            <!-- Put Org Icon Here -->
            <div class="tim-icons icon-molecule-40" style="font-size: 9em; line-height: 120%;">

            </div>
          </div>
          <div class="col-md-9 col-xl-10">
            <!-- Put Org Info Here -->
            <h4 slot="header" class="title">{{ activeOrg.name }}</h4>

            <div class="row">
              <div class="col-md-2"><b>Contact</b></div>
              <div class="col-md-10">
                <p>{{ activeOrg.contact.name }}</p>
                <div v-if="activeOrg.contact.email" class="row ml-0">
                  <div class="tim-icons icon-email-85 my-auto"></div>
                  <p class="my-auto pl-2">{{ activeOrg.contact.email }}</p>
                </div>
                <div v-if="activeOrg.contact.businessphone" class="row ml-0">
                  <div class="tim-icons icon-mobile my-auto"></div>
                  <p class="my-auto pl-2">{{ activeOrg.contact.businessphone }}</p>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-2"><b>Notes</b></div>
              <div class="col-md-10">
                <p>{{ activeOrg.notes }}</p>
              </div>
            </div>
          </div>
        </div>
      </card>
      <card>
        <div class="row mt-3">
          <div class="col-12">
            <div class="row mx-0">
              <h4 slot="header" class="card-title col-md-10">Members for: <b>{{ activeOrg.name }}</b></h4>
              <modal :show.sync="inviteUserModal.inviteOrganisation" body-classes="p-0"
                modal-classes="modal-dialog-centered modal-sm">
                <invite-user :reset="inviteUserModal.reset" :on-invite="onInviteUser" :on-cancel="onCloseInviteModal">
                </invite-user>
              </modal>
            </div>
            <div>
              <div class="col-12 d-flex flex-column flex-md-row justify-content-md-between justify-content-center mb-4">
                <!-- Page Size -->
                <el-select class="col-md-2 col-12 p-0 my-2 select-primary pagination-select mx-md-0 mx-auto"
                  v-model="pagination.perPage" placeholder="Per page">
                  <el-option class="select-primary" v-for="item in pagination.perPageOptions" :key="item" :label="item"
                    :value="item">
                  </el-option>
                </el-select>

                <!-- Search Bar -->
                <div :class="['col-md-8', 'col-12', 'p-0', 'my-2']">
                  <input type="text" :style="searchStyle" v-model="searchQuery" class="form-control" placeholder="Search">
                </div>

                <!-- New Node -->
                <div :class="['col-md-1', 'col-12', 'p-0', 'my-2']">
                  <base-button class="mt-0" block type="success" :disabled="!memberAdmin"
                    @click="inviteUserModal.inviteOrganisation = true">
                    <i class="tim-icons icon-simple-add"></i>
                  </base-button>
                </div>
              </div>

              <!-- Table -->
              <div class="col-12">
                <!-- Header -->
                <div class="col-12 d-flex justify-content-between" style="border-bottom: solid 2px var(--default);">
                  <div class="m-2" :style="{ width: '10px' }"></div>
                  <div class="col-sm-4">
                    <h6>Name</h6>
                  </div>
                  <div class="col-sm-4">
                    <h6>Email</h6>
                  </div>
                  <div class="col-sm-4"></div>
                </div>

                <!-- Body -->
                <div v-if="queriedData">
                  <div class="col-12 d-flex justify-content-between my-2" v-for="member in queriedData"
                    :key="member.uuid" style="border-bottom: 2px solid var(--default)">

                    <!-- Member Name and Status -->
                    <div class="col-sm-4">
                      <p>{{ member.user ? member.user.name : "Pending..." }}</p>
                      <p :style="[chipStyle, memberChip(member.status)]">{{ member.status }}</p>
                    </div>

                    <!-- Member Email -->
                    <div class="col-sm-4 text-center">
                      <p>{{ member.email }}</p>
                    </div>

                    <!-- Admin Action Buttons -->
                    <div class="col-sm-4 text-right" v-if="memberAdmin">
                      <base-button v-if="member.status === 'REQUESTED'" @click.native="handleAcceptJoin(member)"
                        class="remove btn-link" type="success" size="sm">
                        Accept
                      </base-button>
                      <base-button v-if="member.status === 'REQUESTED'" @click.native="handleRejectJoin(member)"
                        class="remove btn-link" type="danger" size="sm">
                        Reject
                      </base-button>
                      <base-button v-if="member.status === 'MEMBER'" @click.native="handlePromote(member)"
                        class="remove btn-link" type="primary" size="sm" icon>
                        <i class="tim-icons icon-minimal-up"></i>
                      </base-button>
                      <base-button v-if="member.status === 'ADMIN' && member.email !== storeUsersProfile.getEmail()"
                        @click.native="handleDemote(member)" class="remove btn-link" type="primary" size="sm" icon>
                        <i class="tim-icons icon-minimal-down"></i>
                      </base-button>
                      <base-button v-if="member.status !== 'REQUESTED' && member.email !== storeUsersProfile.getEmail()"
                        @click.native="handleDelete(member)" class="remove btn-link" type="danger" size="sm" icon>
                        <i class="tim-icons icon-simple-remove"></i>
                      </base-button>
                    </div>
                    <div class="col-sm-4" v-else>

                    </div>
                  </div>
                </div>
                <div v-else>
                  No Data
                </div>
              </div>
            </div>
            <div slot="footer" class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
              <div class="">
                <p class="card-category">
                  Showing {{ from + 1 }} to {{ to }} of {{ total }} entries
                </p>
              </div>
              <base-pagination class="pagination-no-border" v-model="pagination.currentPage"
                :per-page="pagination.perPage" :total="total">
              </base-pagination>
            </div>
          </div>
        </div>
      </card>
    </div>
    <div v-else class="row">
      <div class="col-12 row justify-content-center mt-5">
        <DotLoader />
      </div>
    </div>
  </div>
</template>

<script>

import { Table, TableColumn, Select, Option } from 'element-ui';
import { BasePagination, Modal } from 'src/components';
import BaseAlert from "@/components/BaseAlert";
import alerts from "@/store/modules/alerts";
import Card from '../../components/Cards/Card.vue';
import InviteUser from 'src/tillered/forms/InviteUser.vue';
import DotLoader from 'src/tillered/components/DotLoader.vue';
import Fuse from 'fuse.js';

export default {
  name: "OrgManagement",
  components: {
    BaseAlert,
    BasePagination,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    Card,
    Modal,
    InviteUser,
    DotLoader
  },
  computed: {
    storeState() {
      return this.$store.state;
    },
    storeUsers() {
      return this.$store.state.users;
    },
    storeUsersProfile() {
      return this.storeUsers.userProfile;
    },
    activeOrg() {
      return this.storeUsersProfile.getCompany();
    },
    memberAdmin() {
      let userEmail = this.storeUsersProfile.getEmail();

      let isAdmin = false;

      this.mTableData.map((x) => {
        if (x.email === userEmail) {
          if (x.status === "ADMIN") isAdmin = true;
        }
      })

      return isAdmin;

    },
    queriedData() {
      let result = this.mTableData;
      if (this.searchedData.length > 0) {
        result = this.searchedData;
      }
      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.searchedData.length > 0
        ? this.searchedData.length
        : this.mTableData.length;
    },
    searchStyle() {
      if (this.searchQuery !== '') {
        let result = this.fuseSearch.search(this.searchQuery);
        if (result.length == 0) {
          return {
            borderColor: 'var(--red)',
            color: 'var(--red)'
          }
        }
      }
      return {}
    },
  },
  watch: {
    activeOrg: function (value) {
      const vm = this;

      vm.fetchMemberData(value);
    },
    searchQuery(value) {
      let result = this.mTableData;
      let rv = [];
      if (value !== '') {
        result = this.fuseSearch.search(this.searchQuery);
        for (let i = 0; i < result.length; i++) {
          rv.push(result[i].item)
        }
      }
      this.searchedData = rv;
    },
  },
  data() {
    return {
      alerts,
      pagination: {
        perPage: 50,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50, 100],
        total: 0
      },
      inviteUserModal: {
        reset: 0,
        inviteOrganisation: false,
      },
      chipStyle: {
        borderRadius: '10px',
        textAlign: 'center',
        fontWeight: 'bold',
        display: 'inline-block',
        padding: '0px 10px',
      },
      searchQuery: '',
      propsToSearch: ['user.name', 'email', 'status'],
      mTableData: [],
      searchedData: [],
      fuseSearch: null
    };
  },
  methods: {
    fetchMemberData(value) {
      if (value) {
        // Get all members from organisation
        window.webapi.organisation.getMembers(
          value.uuid, // Active Org
          (data) => {
            this.fuseSearch = new Fuse(data.data, {
              keys: this.propsToSearch,
              threshold: 0.2
            });
            this.mTableData = data.data; // Member Data
          },
          () => { }
        );
      }
    },
    memberChip(status) {
      switch (status) {
        case 'ADMIN': return { backgroundColor: 'var(--info)' };
        case 'MEMBER': return { backgroundColor: 'var(--success)' };
        case 'INVITED': return { backgroundColor: 'var(--warning)' };
      }
      return { backgroundColor: 'var(--danger)' }
    },
    handlePromote(member) {
      window.logger.d(LOG_TAG, 'handlePromote [UNSUPPORTED]', member);
    },
    handleDemote(member) {
      window.logger.d(LOG_TAG, 'handleDemote [UNSUPPORTED]', member);
    },
    handleAcceptJoin(member) {
      if (!this.memberAdmin || !this.activeOrg) return;

      window.logger.d(LOG_TAG, 'handleAcceptJoin', member);
      window.webapi.organisation.acceptMemberJoin(
        this.activeOrg.uuid,
        member.uuid,
        (data) => {
          window.logger.d(LOG_TAG, 'Accepted', member, data);
          this.fetchMemberData(this.activeOrg);

          window.store.dispatch('addSuccess', {
            title: 'Success',
            message: 'Member Accepted',
            timeout: 3000
          }
          )
        },
        () => { }
      )
    },
    handleRejectJoin(member) {
      if (!this.memberAdmin || !this.activeOrg) return;

      window.logger.d(LOG_TAG, 'handleRejectJoin', member);
      window.webapi.organisation.rejectMemberJoin(
        this.activeOrg.uuid,
        member.uuid,
        (data) => {
          window.logger.d(LOG_TAG, 'Rejected', member, data);
          this.fetchMemberData(this.activeOrg);

          window.store.dispatch('addSuccess', {
            title: 'Success',
            message: 'Member Rejected',
            timeout: 3000
          }
          )
        },
        () => { }
      )
    },
    handleDelete(member) {
      if (!this.memberAdmin || !this.activeOrg) return;

      window.logger.d(LOG_TAG, 'handleDelete', member);
      window.webapi.organisation.DeleteMemberJoin(
        this.activeOrg.uuid,
        member.uuid,
        (data) => {
          window.logger.d(LOG_TAG, 'Removed', member, data);
          this.fetchMemberData(this.activeOrg);

          window.store.dispatch('addSuccess', {
            title: 'Success',
            message: 'Member Removed',
            timeout: 3000
          }
          )
        },
        (err) => { }
      )
    },
    /**
     * An Event Handler for the Invite Modal.
     * 
     * @param {Object} members 
     * @param {string[]} members.userInviteEmailList List of Emails to Invite
     */
    onInviteUser(members) {
      const vm = this;
      if (!vm.memberAdmin || !vm.activeOrg) return;

      window.logger.d(LOG_TAG, 'inviteMembers', members);
      window.webapi.organisation.inviteMembers(
        vm.activeOrg.uuid,
        members,
        (data) => {
          window.logger.d(LOG_TAG, 'Invited', data);
          vm.fetchMemberData(vm.activeOrg);

          window.store.dispatch('addSuccess', {
            title: 'Success',
            message: 'Members Invited',
            timeout: 3000
          }
          )
        },
        () => {
          window.store.dispatch('addError', {
            title: 'Error',
            message: 'Failed to invite members',
            timeout: 3000
          }
          )
        }
      );
      vm.onCloseInviteModal();
    },
    onCloseInviteModal() {
      const vm = this;
      window.logger.d(LOG_TAG, 'onCancelModal');
      vm.inviteUserModal.inviteOrganisation = false;
      vm.inviteUserModal.reset = Date.now();
    }
  },
  mounted() {
    // Clear Alerts
    window.store.dispatch('removeAllAlerts');

    // Sync Data
    window.bloc.account.syncUserProfile((data) => {
      if (!data.data.activeOrganisation) {
        window.router.push({ name: 'All Organisations' });
      }
    });
    window.bloc.account.syncUserContacts();

    this.fetchMemberData(this.activeOrg);
  }
}

const LOG_TAG = "Org Management";
</script>
