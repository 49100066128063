<template>
    <card>
        <h6>PRIMARY CONTACT</h6>

        <label>Contact Name *</label>
        <div class="row ">
            <div class="col-md-6">
                <base-input type="text" placeholder="First Name" v-model="contact.fname" required noAutocomplete/>
            </div>
            <div class="col-md-6">
                <base-input type="text" placeholder="Last Name" v-model="contact.lname" required noAutocomplete />
            </div>
        </div>

        <base-input type="text" label="Contact Email" placeholder="Email" v-model="contact.email" required
            @focus="focusedEmail = true" :overrideInvalid="invalidEmail" noAutocomplete />

        <base-input type="text" label="Contact Number" placeholder="+00 000 000 000" v-model="contact.phone" required
            @focus="focusedPhone = true" :overrideInvalid="invalidPhone" noAutocomplete />

        <AddressInput @input='onAddressUpdate' />

    </card>
</template>
<script>

import model from 'src/model';
import AddressInput from 'src/tillered/components/contact/AddressInput.vue';

// Get an instance of `PhoneNumberUtil`.
const phoneUtil = require('google-libphonenumber').PhoneNumberUtil.getInstance();


export default {
    components: {
        AddressInput
    },
    data() {
        return {
            contact: {
                fname: '',
                lname: '',
                email: '',
                phone: '',
                addr: {}
            },
            focusedPhone: false,
            focusedEmail: false,
        };
    },
    computed: {
        invalidPhone() {
            try {
                var tel = phoneUtil.parse(this.contact.phone);
                return this.focusedPhone && !phoneUtil.isValidNumber(tel);
            } catch (error) { }
            return this.focusedPhone;
        },
        invalidEmail() {
            return this.focusedEmail && !model.validateEmail(this.contact.email);
        }
    },
    methods: {
        onAddressUpdate(val) {
            this.contact.addr = val;
        },
        onContactUpdate(val) {
            // Emit new data to parent
            this.$emit('input', val);
        }
    },
    watch: {
        // Check if any data has changed
        contact: {
            handler(val) {
                if (val) {
                    this.onContactUpdate(val);
                }
            },
            deep: true,
        }
    }
}
</script>