<template>
  <div class="content container-lg">
    <div v-if="isLoading" class="row">
      <div class="col-12 row justify-content-center mt-5">
        <DotLoader />
      </div>
    </div>
    <div v-else>

      <div class="row">
        <div v-for="(alert, idx) in alerts.state.alerts" class="col-md-12 alert-container" :key="idx">
          <base-alert :type="alert.type" :icon="alert.icon" :storeindex="idx" dismissible>
            <strong>{{ alert.title }}:</strong> {{ alert.message }}
          </base-alert>
        </div>
      </div>

      <modal :show.sync="mModal.showNode" body-classes="p-0" modal-classes="modal-dialog-centered modal-xl">
        <create-node :reset="mModal.reset" :on-save="onCreateNewNode" :on-cancel="onCloseModal">
        </create-node>
      </modal>

      <div>
        <h2>Nodes</h2>
        <p>A list of all the nodes in your active organsiation.</p>
      </div>
      <div class="row mt-3">
        <div class="col-12">
          <card card-body-classes="table-full-width">
            <h4 slot="header" class="card-title">All Nodes</h4>
            <div>
              <div class="col-12 d-flex flex-column flex-md-row justify-content-md-between justify-content-center mb-4">
                <!-- Page Size -->
                <el-select class="col-md-2 col-12 p-0 my-2 select-primary pagination-select mx-md-0 mx-auto"
                  v-model="pagination.perPage" placeholder="Per page">
                  <el-option class="select-primary" v-for="item in pagination.perPageOptions" :key="item" :label="item"
                    :value="item">
                  </el-option>
                </el-select>

                <!-- Search Bar -->
                <div class="col-md-8 col-12 p-0 my-2">
                  <input type="text" :style="searchStyle" v-model="searchQuery" class="form-control" placeholder="Search">
                </div>

                <!-- New Node -->
                <div class="col-md-1 col-12 p-0 my-2">
                  <base-button :disabled="!storeUserActiveOrg" class="mt-0" block type="success"
                    @click="mModal.showNode = true">
                    <i class="tim-icons icon-simple-add"></i>
                  </base-button>
                </div>
              </div>

              <!-- table -->
              <div>
                <!-- Header -->
                <div class="col-12 d-flex justify-content-between" style="border-bottom: solid 2px #525f7f;">
                  <div class="m-2" :style="{ width: '10px' }"></div>
                  <div class="col-5 col-xl-3 d-flex word-break">
                    <h6>Name/Network</h6>
                  </div>
                  <div class="col-2 col-xl-3 d-flex justify-content-center text-center word-break">
                    <h6>Public/Private Ip</h6>
                  </div>
                  <div class="col-1 col-xl-2 d-flex text-center justify-content-center">
                    <h6>Cloud</h6>
                  </div>
                  <div class="col-2">
                    <h6>Disk Storage</h6>
                  </div>
                  <div class="col-1 d-flex justify-content-center"></div>
                </div>

                <!-- Body -->
                <div v-if="queriedData">

                  <div v-for="row in queriedData" :key="row.uuid" class="col-12 d-flex justify-content-between my-2"
                    style="border-bottom: solid 1px #525f7f;">

                    <!-- Node Status -->
                    <StatusIndicator :stylecolor="getNodeStatus(row)" :toolmessage="getToolMesage(row)" />

                    <!-- Node Type Icon + Name and Network -->
                    <div class="col-5 col-xl-3 d-flex">
                      <div class="d-flex m-2">
                        <i :id="`tooltip-target-${row.uuid}`" :class="['my-auto', 'tim-icons', getNodeType(row)]"
                          style="font-size: 1.8rem;"></i>
                      </div>
                      <div class="d-flex">
                        <div class="my-auto">
                          <p class="mb-0">{{ row.name }}</p>
                          <span>{{ getNetworkName(row) }}</span>
                        </div>
                      </div>
                    </div>

                    <!-- Node Public and Private IPs -->
                    <div class="col-2 col-xl-3 d-flex justify-content-center text-center">
                      <div class="my-auto">
                        <p>{{ row.public_ipv4 }}</p>
                        <p>{{ row.private_ipv4 }}</p>
                      </div>
                    </div>

                    <!-- Node Cloud Provider -->
                    <div class="col-1 col-xl-2 d-flex text-center justify-content-center">
                      <p class="my-auto"><b>{{ row.cloud_provider }}</b></p>
                    </div>

                    <!-- Node Storage -->
                    <div class="col-2">
                      <div class="progress-container progress-default progress-sm">
                        <span v-if="row.disk_free_percentage > 20" class="progress-value">{{ getNodeStorageText(row)
                        }}%</span>
                        <span v-else class="progress-value" style="color: var(--danger)">{{ getNodeStorageText(row)
                        }}%</span>
                        <div class="progress">
                          <div role="progressbar" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"
                            class="progress-bar" :style="getNodeStorageWidth(row)"></div>
                        </div>
                      </div>
                    </div>

                    <!-- Edit Button -->
                    <div class="col-1 d-flex justify-content-center">
                      <base-button @click.native="onNodeEdit(row)" class="my-auto edit btn-link" type="warning"
                        size="sm" icon>
                        <i class="tim-icons icon-pencil"></i>
                      </base-button>
                    </div>

                  </div>
                </div>
                <div v-else-if="!isDataLoading" class="col-12 d-flex justify-content-center my-2">
                  <DotLoader />
                </div>
                <div v-else class="col-12 d-flex justify-content-center my-2"></div>
              </div>

            </div>
            <div slot="footer" class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
              <div class="">
                <p class="card-category">
                  Showing {{ from + 1 }} to {{ to }} of {{ total }} entries
                </p>
              </div>
              <base-pagination class="pagination-no-border" v-model="pagination.currentPage"
                :per-page="pagination.perPage" :total="total">
              </base-pagination>
            </div>
          </card>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Table, TableColumn, Select, Option } from 'element-ui';
import { BasePagination } from 'src/components';
import { Modal } from 'src/components'
import Fuse from 'fuse.js';
import model from 'src/model';
import alerts from "src/store/modules/alerts";
import CreateNode from 'src/tillered/forms/CreateNode.vue';
import DotLoader from 'src/tillered/components/DotLoader.vue';
import StatusIndicator from 'src/tillered/components/StatusIndicator.vue';

const LOG_TAG = 'nodes';

export default {
  components: {
    BasePagination,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    Modal,
    CreateNode,
    DotLoader,
    StatusIndicator
  },
  computed: {
    storeState() {
      return this.$store.state;
    },
    storeNodes() {
      return this.$store.state.nodes;
    },
    storeNodesList() {
      return this.storeNodes.list;
    },
    storeUsers() {
      return this.$store.state.users;
    },
    storeUserProfile() {
      return this.storeUsers.userProfile;
    },
    storeUserActiveOrg() {
      return this.storeUserProfile.hasCompany();
    },
    /***
     * Returns a page from the searched data or the whole data. Search is performed in the watch section below
     */
    queriedData() {
      let result = this.mTableData;
      if (this.searchedData.length > 0) {
        result = this.searchedData;
      }
      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.searchedData.length > 0
        ? this.searchedData.length
        : this.mTableData.length;
    },
    isLoading() {
      if (this.storeUserProfile.data === undefined) {
        return true;
      }
      return !this.storeUserProfile.data.active
    },
    isDataLoading() {
      return this.loadingNodeData;
    },
    searchStyle() {
      if (this.searchQuery !== '') {
        let result = this.fuseSearch.search(this.searchQuery);
        if (result.length == 0) {
          return {
            borderColor: 'var(--red)',
            color: 'var(--red)'
          }
        }
      }
      return {}
    }
  },
  data() {
    return {
      alerts,
      mModal: {
        reset: 0,
        showNode: false,
        node: null,
        close() {
          this.showNode = false;
          this.node = null;
          this.reset = Date.now();
        }
      },
      modal: false,
      pagination: {
        perPage: 50,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50, 100],
        total: 0
      },
      searchQuery: '',
      propsToSearch: ['uuid', 'name', 'private_ipv4', 'public_ipv4', 'networks.name', 'cloud_provider'],
      mTableData: [],
      searchedData: [],
      fuseSearch: null,
      loadingNetData: true,
    };
  },
  watch: {
    searchQuery(value) {
      let result = this.mTableData;
      let rv = [];
      if (value !== '') {
        result = this.fuseSearch.search(this.searchQuery);
        for (let i = 0; i < result.length; i++) {
          rv.push(result[i].item)
        }
      }
      this.searchedData = rv;
    },
    mTableData(value) {
      this.fuseSearch = new Fuse(value, {
        keys: this.propsToSearch,
        threshold: 0.2
      });
    },
    'storeUserProfile.data.activeOrganisation'(value) {
      this.syncNodes();
    }
  },
  mounted() {
    const vm = this;
    vm.loadingNodeData = true;
    window.store.dispatch('removeAllAlerts');

    vm.syncNodes();

  },
  methods: {
    syncNodes() {
      const vm = this;
      window.bloc.account.syncTemplateList(
        (data) => {
        }, (error) => {

        });
      window.bloc.account.syncUserNodes(
        (data) => {
          vm.mTableData = vm.storeNodesList;

          // Fuse search initialization.
          vm.fuseSearch = new Fuse(vm.mTableData, {
            keys: vm.propsToSearch,
            threshold: 0.3
          });

          vm.loadingNodeData = false;

        }, (error) => {

        });
    },
    handleChange(value) {
      console.log(value);
    },
    getNetworkName(row) {
      let networkName = "No Network"
      if (row.networks) {
        if (row.networks.length > 0) {
          row.networks.forEach((network) => {
            if (network.active)
              networkName = network.name;
          })
        }
      }
      return networkName;
    },
    getNodeType(row) {
      return model.getNodeIcon(row.type);
    },
    getNodeStatus(row) {
      return model.getNodeStatus(row);
    },
    getToolMesage(row) {
      return model.getNodeStatusText(row);
    },
    getNodeStorageText(row) {
      return (100 - row.disk_free_percentage).toFixed(2)
    },
    getNodeStorageWidth(row) {
      if (100 - row.disk_free_percentage > 80) {
        return "width: " + this.getNodeStorageText(row) + "%; background-color: var(--danger);"
      }
      return "width: " + this.getNodeStorageText(row) + "%"
    },
    onNodeEdit(row) {
      window.router.push(
        {
          name: 'Node Details',
          params: { uuid: row.getUuid() }
        }
      );
    },
    onCreateNewNode(data) {
      const vm = this;
      window.logger.d(LOG_TAG, 'onCreateNewNode', data);
      if (data) {
        window.bloc.update.createNodeOrder(
          data,
          (data) => {
            window.store.dispatch('addSuccess', {
              title: 'Success',
              message: 'Node created successfully',
              timeout: 3000
            }
            )
            vm.mTableData = data;
            vm.mModal.close();
          },
          (error) => {
            window.store.dispatch('addError', {
              title: 'Error',
              message: model.error(error.error).formattedErrorMessage(),
              timeout: 3000
            }
            )
          }
        );
      } else {
        vm.mModal.close();
      }
    },
    onCloseModal() {
      const vm = this;
      window.logger.d(LOG_TAG, 'onCancelModal');
      vm.mModal.close();
    }
  },
};
</script>
<style>
.pagination-select,
.search-input {
  width: 200px;
}

.swal2-icon-content {
  font-size: inherit !important;
}

.el-table .cell {
  word-break: normal;
}
</style>
