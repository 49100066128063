<template>
  <card type="secondary" class="border-0 mb-0">
    <form @submit.prevent="onAddNode">
      <h6 slot="header" class="modal-title" id="modal-title-new">Add Node to Network</h6>
      <span>The following are a list of nodes in your org that aren't attached to a network.</span>
      <div class="mt-2"></div>

      <div>
        <div v-for="(item, key) in nodes" :key="key">
          <div
            :class="[
              'd-flex', 
              'py-1', 'px-2', 'm-1', 
              'justify-content-between', 
              'border', 'border-default', 'rounded',
              selectedNodes.includes(item.uuid) ? 'bg-default' : '',
              ]" @click="() => onSelectNode(item)">
            
            <!-- Node Icon -->
            <p :class="['tim-icons', getNodeType(item), 'my-auto', 'text-white', 'user-select-none']" style="cursor: pointer; font-size: 1.8rem;" />
            
            <!-- Name and UUID -->
            <div class="my-auto user-select-none">
              <p class="text-white user-select-none m-0">{{ item.name }}</p>
              <span class="user-select-none">{{ item.uuid }}</span>
            </div>
            
            <p class="my-auto text-white user-select-none">{{ item.public_ipv4 }}</p>

          </div>
        </div>
      </div>

      <div class="mt-4"></div>
      <div class="d-flex flex-row justify-content-between">
        <base-button native-type="submit" type="primary" class="btn-fill" :disabled="selectedNodes.length < 1">
          Add
        </base-button>
        <base-button type="secondary" class="btn-fill" v-on:click.prevent="onCancelSelect">
          Cancel
        </base-button>
      </div>
    </form>

  </card>
</template>
<script>


export default {

  props: {
    nodes: {
      type: Array,
      required: true,
    },
    onSave: {
      type: Function,
      required: true,
    },
    onCancel: {
      type: Function,
      required: true,
    },
    reset: {
      type: Number,
      default: function () {
        return 0;
      }
    }
  },
  data() {
    return {
      selectedNodes: [],
    }
  },
  mounted() {},
  methods: {
    onSelectNode(node) {
      if (this.selectedNodes.includes(node.uuid)) {
        this.selectedNodes = this.selectedNodes.filter((n) => n !== node.uuid);
      } else {
        this.selectedNodes.push(node.uuid);
      }
    },
    onAddNode() {
      this.onSave(this.selectedNodes);
      this.selectedNodes = [];
    },
    onCancelSelect() {
      this.onCancel();
      this.selectedNodes = [];
    },
    getNodeType(row) {
      switch (row.type) {
        case 'CEN': return 'icon-cloud-upload-94';
      };

      return 'icon-upload';
    },
  }

};

</script>