<template>
  <div class="content container-lg">
    <div v-if="isLoading" class="row">
      <div class="col-12 row justify-content-center mt-5">
        <DotLoader />
      </div>
    </div>
    <div v-else>
      <div class="row">
        <div v-for="(alert, idx) in alerts.state.alerts" class="col-md-12 alert-container" :key="idx">
          <base-alert :type="alert.type" :icon="alert.icon" :storeindex="idx" dismissible>
            <strong>{{ alert.title }}:</strong> {{ alert.message }}
          </base-alert>
        </div>
      </div>

      <modal :show.sync="mCreateModal.show" body-classes="p-0" modal-classes="modal-dialog-centered modal-sm">
        <create-network :reset="mCreateModal.reset" :on-save="onCreateNetworkSaveCb" :on-cancel="onCloseCreateModalCb">
        </create-network>
      </modal>
      <modal :show.sync="mAddModal.show" body-classes="p-0" modal-classes="modal-dialog-centered modal-md">
        <add-node :reset="mAddModal.reset" :on-save="onNodeAddCb" :on-cancel="onCloseNodeAddCb" :nodes="avaliableNodes">
        </add-node>
      </modal>

      <div>
        <h2>Networks</h2>
        <p>A list of all the networks in your active organsiation.</p>
      </div>
      <div class="row">
        <div class="col-12 mt-3">
          <card card-body-classes="table-full-width">
            <h4 slot="header" class="card-title">All Networks</h4>
            <div>
              <div class="col-12 d-flex flex-column flex-md-row justify-content-md-between justify-content-center mb-4">
                <!-- Page Size -->
                <el-select class="col-md-2 col-12 p-0 my-2 select-primary pagination-select mx-md-0 mx-auto"
                  v-model="pagination.perPage" placeholder="Per page">
                  <el-option class="select-primary" v-for="item in pagination.perPageOptions" :key="item" :label="item"
                    :value="item">
                  </el-option>
                </el-select>

                <!-- Search Bar -->
                <div class="col-md-8 col-12 p-0 my-2">
                  <input type="text" :style="searchStyle" v-model="searchQuery" class="form-control" placeholder="Search">
                </div>

                <!-- New Network -->
                <div class="col-md-1 col-12 p-0 my-2">
                  <base-button class="mt-0" block type="success" @click="mCreateModal.show = true">
                    <i class="tim-icons icon-simple-add"></i>
                  </base-button>
                </div>
              </div>

              <!-- table -->
              <div>
                <!-- Header -->
                <div class="col-12 d-flex justify-content-between" style="border-bottom: solid 2px #525f7f;">
                  <div class="m-2" :style="{ width: '10px' }"></div>
                  <div class="col-5 col-xl-3 d-flex word-break">
                    <h6>Name</h6>
                  </div>
                  <div class="col-2 col-xl-3 d-flex justify-content-center text-center word-break">
                    <h6>Nodes</h6>
                  </div>
                  <div class="col-1 d-flex justify-content-center"></div>
                </div>

                <!-- Body -->
                <div v-if="queriedData.length">
                  <div v-for="row in queriedData" :key="row.uuid" class="col-12 d-flex justify-content-between my-2"
                    style="border-bottom: solid 1px #525f7f;">

                    <!-- Network Status -->
                    <StatusIndicator :stylecolor="getNetworkStatus(row)" :toolmessage="getNetworkStatusText(row)" />

                    <!-- Network Name + UUID -->
                    <div class="col-5 col-xl-3 d-flex">
                      <div class="d-flex">
                        <div class="my-auto">
                          <p class="mb-0">{{ row.name }}</p>
                          <span>{{ row.uuid }}</span>
                        </div>
                      </div>
                    </div>

                    <!-- Node Count -->
                    <div class="col-2 col-xl-3 d-flex justify-content-center text-center">
                      <div class="my-auto">
                        <p>{{ row.nodeCount }}</p>
                      </div>
                    </div>

                    <!-- Action Buttons -->
                    <div class="col-1 d-flex justify-content-center">

                      <!-- Add Node to Network Button -->
                      <base-button v-if="TilleredOrg" @click.native="() => { mAddModal.selectNetwork = row.uuid; mAddModal.show = true; }"
                        class="my-auto edit btn-link" type="success" size="sm" icon>
                        <i class="tim-icons icon-simple-add"></i>
                      </base-button>
                      <!-- Edit Button -->
                      <base-button @click.native="onNetworkEdit(row)" class="my-auto edit btn-link" type="warning"
                        size="sm" icon>
                        <i class="tim-icons icon-pencil"></i>
                      </base-button>
                    </div>
                  </div>
                </div>
                <div v-else-if="isDataLoading" class="col-12 row justify-content-center mt-5">
                  <DotLoader />
                </div>
                <div v-else class="col-12 row justify-content-center mt-5">
                </div>
              </div>
            </div>
            <div slot="footer" class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
              <div class="">
                <p class="card-category">
                  Showing {{ from + 1 }} to {{ to }} of {{ total }} entries
                </p>
              </div>
              <base-pagination class="pagination-no-border" v-model="pagination.currentPage"
                :per-page="pagination.perPage" :total="total">
              </base-pagination>
            </div>
          </card>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Table, TableColumn, Select, Option } from 'element-ui';
import { BasePagination } from 'src/components';
import { Modal } from 'src/components'
import Fuse from 'fuse.js';
import model from 'src/model';
import alerts from "src/store/modules/alerts";
import CreateNetwork from 'src/tillered/forms/CreateNetwork.vue';
import AddNode from 'src/tillered/forms/AddNode.vue';
import { mapGetters } from "vuex";
import DotLoader from 'src/tillered/components/DotLoader.vue';
import StatusIndicator from 'src/tillered/components/StatusIndicator.vue';


const LOG_TAG = 'nodes';

export default {
  components: {
    BasePagination,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    Modal,
    CreateNetwork,
    AddNode,
    DotLoader,
    StatusIndicator
  },
  computed: {
    storeState() {
      return this.$store.state;
    },
    storeNetworks() {
      return this.$store.state.networks;
    },
    storeNodes() {
      return this.$store.state.nodes;
    },
    storeNetworksList() {
      return this.storeNetworks.list;
    },
    storeNodesList() {
      return this.storeNodes.list;
    },
    storeUsers() {
      return this.$store.state.users;
    },
    storeUserProfile() {
      return this.storeUsers.userProfile;
    },

    /***
     * Returns a page from the searched data or the whole data. Search is performed in the watch section below
     */
    queriedData() {
      let result = this.mTableData;
      if (this.searchedData.length > 0) {
        result = this.searchedData;
      }
      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.searchedData.length > 0
        ? this.searchedData.length
        : this.mTableData.length;
    },
    nodeCount() {
      return this.mTableData.nodes.length
    },
    avaliableNodes() {
      return this.storeNodesList.filter((node) => !node.networks)
    },
    isLoading() {
      if (this.storeUserProfile.data === undefined) {
        return true;
      }
      return !this.storeUserProfile.data.active;
    },
    isDataLoading() {
      return this.loadingNetData;
    },
    searchStyle() {
      if (this.searchQuery !== '') {
        let result = this.fuseSearch.search(this.searchQuery);
        if (result.length == 0) {
          return {
            borderColor: 'var(--red)',
            color: 'var(--red)'
          }
        }
      }
      return {}
    },
    TilleredOrg() {
      return "Tillered Limited" === this.storeUserProfile.getCompanyName();
    },
    ...mapGetters(["userProfile"])

  },
  data() {
    return {
      alerts,
      mCreateModal: {
        reset: 0,
        show: false,
        close() {
          this.show = false;
          this.reset = Date.now();
        }
      },
      mAddModal: {
        selectNetwork: '',
        reset: 0,
        show: false,
      },
      modal: false,
      pagination: {
        perPage: 50,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50, 100],
        total: 0
      },
      searchQuery: '',
      propsToSearch: ['name', 'uuid'],
      mTableData: [],
      mNodeData: [],
      searchedData: [],
      fuseSearch: null,
      loadingNetData: true,
    };
  },
  watch: {
    searchQuery(value) {
      let result = this.mTableData;
      let rv = [];
      if (value !== '') {
        result = this.fuseSearch.search(this.searchQuery);
        for (let i = 0; i < result.length; i++) {
          rv.push(result[i].item)
        }
      }
      this.searchedData = rv;
    },
    mTableData(value) {
      // Fuse search initialization.
      this.fuseSearch = new Fuse(value, {
        keys: this.propsToSearch,
        threshold: 0.2
      });
    },
    'storeUserProfile.data.activeOrganisation'(value) {
      this.syncNetworkNodes();
    }
  },
  mounted() {
    const vm = this;
    window.store.dispatch('removeAllAlerts');

    vm.syncNetworkNodes();

    window.bloc.account.syncUserOrganisations();
  },
  methods: {
    syncNetworkNodes() {
      const vm = this;
      vm.loadingNetData = true;

      window.bloc.account.syncUserNetworks(() => {
        window.bloc.account.syncUserNodes((nodes) => {
          vm.mNodeData = nodes;
          vm.mTableData = vm.storeNetworksList;

          // Fuse search initialization.
          vm.fuseSearch = new Fuse(vm.mTableData, {
            keys: vm.propsToSearch,
            threshold: 0.3
          });
          vm.loadingNetData = false;
        });
      });
    },
    getNetworkStatus(row) {
      if (!row.nodes) return 'var(--success)';

      let result = this.mNodeData
        .filter(node => row.nodes.includes(node.uuid))
        .every(node => node.provisioning_state == 'READY' && node.authorized && !model.nodeOutOfDate(node));

      return result ? 'var(--success)' : 'var(--warning)';
    },
    getNetworkStatusText(row) {
      if (!row.nodes) return 'Network is OK';

      let problematicNodes = 0;
      this.mNodeData
        .filter(node => row.nodes.includes(node.uuid))
        .map(node => {
          if (!(node.provisioning_state == 'READY' && node.authorized && !model.nodeOutOfDate(node))) 
            problematicNodes++;
        })

      return problematicNodes === 0 ? 'Network is OK' : `${problematicNodes} problematic nodes`;
    },
    onNetworkEdit(row) {
      window.router.push({ name: 'Network Details', params: { uuid: row.getUuid() } });
    },
    onCreateNetworkSaveCb(data) {
      const vm = this;
      window.logger.d(LOG_TAG, 'onCreateNewNetwork', data);
      if (data) {
        window.bloc.update.createNetwork(
          data,
          (data) => {
            window.store.dispatch('addSuccess', {
              title: 'Success',
              message: 'Network created successfully',
              timeout: 3000
            }
            )
            vm.mTableData = data;
            vm.mCreateModal.close();
          },
          (error) => {
            window.store.dispatch('addError', {
              title: 'Error',
              message: model.error(error.error).formattedErrorMessage(),
              timeout: 3000
            }
            )
          }
        );
      } else {
        vm.mCreateModal.close();
      }
    },
    onCloseCreateModalCb() {
      const vm = this;
      window.logger.d(LOG_TAG, 'onCancelCreateModal');
      vm.mCreateModal.close();
    },
    onCloseEditModalCb() {
      const vm = this;
      window.logger.d(LOG_TAG, 'onCancelEditModal');
      vm.mEditModal.close();
    },
    onNodeAddCb(data) {
      window.logger.d('submitted node adding to net', data);
      data.forEach((nodeUuid) => {
        window.webapi.network.addNode(this.mAddModal.selectNetwork, nodeUuid,
          () => {
            window.store.dispatch('addSuccess', {
              title: 'Success',
              message: 'Node ' + nodeUuid + ' added to ' + this.networkName,
              timeout: 3000
            });
            this.syncNetworkNodes();
          },
          (error) => {
            window.store.dispatch('addError', {
              title: 'Error',
              message: model.error(error.error).formattedErrorMessage(),
              timeout: 3000
            });
          })
      });
      this.mAddModal.show = false;
    },
    onCloseNodeAddCb() {
      this.mAddModal.show = false;
    },
    onNodeEdit(row) {
      window.router.push(
        {
          name: 'NodeDetails',
          query: { uuid: row.getUuid() }
        }
      );
    },
  },
};
</script>
<style>
.pagination-select,
.search-input {
  width: 200px;
}

.swal2-icon-content {
  font-size: inherit !important;
}

.el-table .cell {
  word-break: normal;
}
</style>
